import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Landing from './pages/landing/Landing';
import AuthenticatedLayout from './layouts/authenticated/AuthenticatedLayout';
import CancelledPayment from './pages/CancelledPayment';
import Home from './pages/home/Home';
import SuccessfulPayment from './pages/SuccessfulPayment';
import GenreNavigation from './pages/genreNavigation/GenreNavigation';
import Profile from './pages/profile/Profile';
import WatchContent from './pages/watchContent/WatchContent';
import WatchLater from './pages/watchLater/WatchLater';
import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
import NotFound from './pages/NotFound';
import { ContentDetails } from './pages/contentDetails/ContentDetails';

const router = createBrowserRouter([
  {
    children: [
      {
        path: '/',
        element: <Landing />,
      },
      {
        path: '/sign-in',
        element: <SignInPage />,
      },
      {
        path: '/sign-up',
        element: <SignUpPage />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: '/success',
        element: <SuccessfulPayment />,
      },
      {
        path: '/cancel',
        element: <CancelledPayment />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/watchlater',
        element: <WatchLater />,
      },
      {
        path: '/home/profile',
        element: <Profile />,
      },
      {
        path: '/details/series/:contentId',
        element: <ContentDetails contentType="series" />,
      },
      {
        path: '/details/movie/:contentId',
        element: <ContentDetails contentType="movie" />,
      },
      {
        path: '/series/genres',
        element: <GenreNavigation contentType="series" />,
      },
      {
        path: '/series/genres/:genreId',
        element: <GenreNavigation contentType="series" />,
      },
      {
        path: '/movie/genres',
        element: <GenreNavigation contentType="movie" />,
      },
      {
        path: '/movie/genres/:genreId',
        element: <GenreNavigation contentType="movie" />,
      },
      {
        path: '/watch/:contentType/:contentId',
        element: <WatchContent />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}
export default App;

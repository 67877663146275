import * as React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { AuthContext, AuthContextType } from '../../contexts';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Content } from '../../types/Content';
import { EpisodeMetadata } from './components/EspisodeMetadata';
import { EpisodeProjection } from '../../types/EpisodeProjection';
import { MovieMetadata } from './components/MovieMetadata';
import { movieServiceUrl, serieServiceUrl } from '../../urls';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomPlayer from '../../components/generics/CustomPlayer';
import './WatchContent.css';

function WatchContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { contentType, contentId } = useParams();
  const authContext = React.useContext(AuthContext);
  const [content, setContent] = React.useState<Content | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    const getMovieMetadata = async (
      movieId: string | undefined,
      authContext: AuthContextType,
    ) => {
      const response = await fetch(movieServiceUrl + '/' + movieId, {
        mode: 'cors',
        headers: { Authorization: `Bearer ${authContext.token}` },
      });
      const content: Content = await response.json();
      setContent(content);
      setIsLoading(false);
    };

    const getEpisodeMetadata = async (
      episodeId: string | undefined,
      authContext: AuthContextType,
    ) => {
      const response = await fetch(
        serieServiceUrl + '?episodeId=' + episodeId,
        {
          mode: 'cors',
          headers: { Authorization: `Bearer ${authContext.token}` },
        },
      );
      const projection = (await response.json()) as EpisodeProjection;
      setContent({ ...projection.episode });
      setIsLoading(false);
    };

    if (contentType === 'movie') {
      getMovieMetadata(contentId, authContext);
    }

    if (contentType === 'series') {
      getEpisodeMetadata(contentId, authContext);
    }
  }, [contentType, contentId, authContext]);

  const goBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <Box>
        <CircularProgress
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    );
  }

  return (
    <Grid container mt={5}>
      <Grid container mb={4}>
        <Grid item xs={6} pl={{ xs: 2, md: 16 }}>
          <Typography variant="h4" color={'white'}>
            {content?.title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            flexDirection: 'row-reverse',
            alignItems: 'center',
            display: 'flex',
          }}
          pr={{ xs: 2, sm: 16 }}
        >
          <Button
            variant="contained"
            onClick={goBack}
            startIcon={<ArrowBack />}
            sx={{ height: 40 }}
          >
            {t('common.back')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} pl={{ xs: 2, md: 16 }} pr={{ xs: 2 }}>
        <Box
          sx={{
            pb: 1,
            mr: { xs: 0, md: 8 },
          }}
        >
          <CustomPlayer contentId={contentId!} contentType={contentType!} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        px={{ xs: 2, md: 0 }}
        pr={{ xs: 2, md: 14 }}
        mt={{ xs: 2, sm: 0 }}
      >
        <Typography variant="body2" color={'lightgray'}>
          {content?.description}
        </Typography>
        <Box mt={2}>
          {contentType === 'movie' ? (
            <MovieMetadata content={content!} />
          ) : null}
          {contentType === 'series' ? (
            <EpisodeMetadata episode={content!} />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
}

export default WatchContent;

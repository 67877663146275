import { createTheme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    discreteWhite: true;
  }
}

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'discreteWhite' },
          style: {
            textTransform: 'none',
            border: `1px solid`,
            fontSize: 13,
            height: 33,
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fafafa',
            },
            color: 'lightgray',
            borderColor: 'lightgray',
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontFamily: 'Lexend',
          backgroundColor: '#570A57',
          '&:hover': {
            backgroundColor: '#460946',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#5a3951',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          height: '100%',
          backgroundImage: `linear-gradient(19deg, #241222 0%, #5a3951 100%)`,
        },
      },
    },
  },
});

theme.shape.borderRadius = 0;

theme.typography.body1.fontFamily = 'Lexend';
theme.typography.body2.fontFamily = 'Lexend';

theme.typography.h4 = {
  fontFamily: 'Lexend',
  fontSize: '1.5rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

theme.typography.h5 = {
  fontFamily: 'Lexend',
  fontSize: '1.0rem',
  '@media (min-width:600px)': {
    fontSize: '1.0rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
};

theme.typography.h6 = {
  fontFamily: 'Lexend',
  fontSize: '0.8rem',
  '@media (min-width:600px)': {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.0rem',
  },
};

import React from 'react';
import { Box, Button, Rating, Typography } from '@mui/material';
import { useFetchContentRatingQuery } from '../../store';
import { AuthContext } from '../../contexts';
import RatingDialog from './RatingDialog';
import { round } from 'lodash';

interface RatingsProps {
  contentType: string;
  contentId: string;
}

export default function Ratings(props: RatingsProps) {
  const context = React.useContext(AuthContext);

  const [open, setOpen] = React.useState(false);

  const contentRating = useFetchContentRatingQuery({
    contentType: props.contentType,
    contentId: props.contentId,
    token: context.token,
  });

  if (contentRating.isLoading) {
    return <Typography>Loading</Typography>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: 200,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          backgroundColor: '#583f58',
          border: 'none',
          '&:hover': { backgroundColor: '#775977', border: 'none' },
        }}
      >
        <Rating
          size="small"
          readOnly
          name="rating"
          precision={0.5}
          value={
            contentRating.data.length > 0
              ? contentRating.data[0].averageRating
              : 0
          }
        />
        <Typography sx={{ ml: 1, mr: 1 }} variant="body2" color={'white'}>
          {contentRating.data.length > 0
            ? round(contentRating.data[0].averageRating, 2)
            : 0}
        </Typography>
      </Button>
      <RatingDialog
        open={open}
        onClose={handleClose}
        contentId={props.contentId}
        contentType={props.contentType}
      />
    </Box>
  );
}

import * as React from 'react';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useTranslation } from 'react-i18next';

function SecuritySettings() {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string | unknown>('');
  const [success, setSuccess] = React.useState<boolean>(false);

  const handlePasswordSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(authenticatedUser, oldPassword, newPassword);
    } catch (e) {
      setErrorMessage(e);
      return;
    }
    setSuccess(true);
  };

  const renderResult = () => {
    if (errorMessage) {
      return (
        <Alert severity="error">
          {String(errorMessage).split(':').splice(-1)}
        </Alert>
      );
    }

    if (success) {
      return (
        <Alert severity="success">
          {t('securitySettings.passwordUpdated')}
        </Alert>
      );
    }
  };

  return (
    <form onSubmit={handlePasswordSubmit}>
      <Typography variant="body2">
        {t('securitySettings.changePasswordQuestion')}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        {t('securitySettings.provideNewPassword')}
      </Typography>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        sx={{ mt: 2, mb: 4 }}
        spacing={0}
      >
        <Grid item xs={9} sm={4} sx={{ mx: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="old-password"
            label={t('securitySettings.currentPassword')}
            name="old-password"
            variant="outlined"
            type="password"
            autoComplete="old-password"
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={9} sm={4} sx={{ mx: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="new-password"
            label={t('securitySettings.newPassword')}
            name="new-password"
            autoComplete="new-password"
            variant="outlined"
            type="password"
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={8}>
          {newPassword ? (
            <PasswordStrengthBar
              password={newPassword}
              shortScoreWord={t('securitySettings.tooShort')}
              scoreWords={[
                t('securitySettings.tooWeak'),
                t('securitySettings.weak'),
                t('securitySettings.okay'),
                t('securitySettings.good'),
                t('securitySettings.strong'),
              ]}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {renderResult()}
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2, mb: 4, float: 'right' }}
      >
        {t('securitySettings.updatePassword')}
      </Button>
    </form>
  );
}

export default SecuritySettings;

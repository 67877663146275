import React, { useState, useEffect, useContext } from 'react';
import GenreNavigationBox from './components/GenreNavigationBox';
import { AuthContext, AuthContextType } from '../../contexts';
import { genreServiceUrl } from '../../urls';
import { Box, CircularProgress, Typography } from '@mui/material';
import GenreContentNavigation from './components/genreContentNavigation/GenreContentNavigation';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

interface GenreNavigationProps {
  contentType: string;
}

function GenreNavigation(props: GenreNavigationProps) {
  const { t } = useTranslation();
  const [genres, setGenres] = useState([]);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let { genreId } = useParams();

  useEffect(() => {
    if (props.contentType === 'movie') {
      getGenresMovies(authContext);
    }

    if (props.contentType === 'series') {
      getGenresSeries(authContext);
    }
  }, [props, authContext]);

  const getGenresMovies = async (authContext: AuthContextType) => {
    const response = await fetch(genreServiceUrl + '/content/movies', {
      mode: 'cors',
      headers: { Authorization: `Bearer ${authContext.token}` },
    });
    const jsonData = await response.json();
    setGenres(jsonData);
    setIsLoading(false);
  };

  const getGenresSeries = async (authContext: AuthContextType) => {
    const response = await fetch(genreServiceUrl + '/content/series', {
      mode: 'cors',
      headers: { Authorization: `Bearer ${authContext.token}` },
    });
    const jsonData = await response.json();
    setGenres(jsonData);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Box>
        <CircularProgress
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    );
  }

  const renderContent = () => {
    if (genreId) {
      return <GenreContentNavigation contentType={props.contentType} />;
    } else {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 15,
          }}
        >
          <SearchIcon sx={{ fontSize: 100, color: '#ffffff4a' }} />
          <Typography color="white">
            {t('contentNavigation.selectGenre')}
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Box>
      <GenreNavigationBox contentType={props.contentType} genres={genres} />
      {renderContent()}
    </Box>
  );
}

export default GenreNavigation;

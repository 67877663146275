import React from 'react';
import { AuthContext } from '../../../contexts';
import { useFetchChannelByIdQuery } from '../../../store';
import { CircularProgress } from '@mui/material';

interface ChannelTextProps {
  channelId: string;
}

export function ChannelText(props: ChannelTextProps) {
  const context = React.useContext(AuthContext);
  const { data, isLoading } = useFetchChannelByIdQuery({
    id: props.channelId,
    token: context.token,
  });

  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }

  return <span>{data?.title}</span>;
}

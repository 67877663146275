import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { FaDiscord } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        pt: 2,
        pb: 1,
        height: 'auto',
        background: '#3b00514d',
        color: 'white',
        alignItems: 'end',
        justifyItems: 'end',
      }}
    >
      <Grid container>
        <Grid item xs={7}>
          <Typography
            sx={{
              pt: 1,
              pl: { xs: 2, sm: '20%', md: '20%', lg: '25%' },
              fontWeight: 100,
              fontSize: { xs: 13, sm: 14 },
            }}
          >
            {t('landing.followUs')}!
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            sx={{
              color: '#d0cad4',
              fontSize: 34,
              textAlign: 'right',
              pr: { xs: 2, sm: '30%' },
            }}
          >
            <a
              href="https://www.linkedin.com/company/bingebox/"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <FaLinkedin style={{ marginRight: 8 }} />
            </a>
            <a
              href="https://www.instagram.com/bingeboxnl/"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <FaInstagram style={{ marginRight: 8 }} />
            </a>
            <a
              href="https://discord.gg/khjXkyypPp"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <FaDiscord />
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Footer;

import React from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function InfoBox() {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        width: {
          xs: 360,
          sm: 600,
          md: 800,
        },
        background: '#3b00514d',
        color: 'white',
        pb: 5,
      }}
    >
      <Box sx={{ px: 2, py: 1, backgroundColor: '#00000030' }}>
        <Typography variant="body2">{t('landing.addYourContent')}</Typography>
      </Box>
      <Grid
        container
        sx={{
          mt: 5,
          px: {
            xs: 3,
            sm: 10,
          },
        }}
      >
        <Grid item xs={12}>
          <Typography variant={'h4'} textAlign={'center'}>
            {t('landing.yourDistributor')}
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Typography variant={'body2'} fontWeight={'100'}>
            {t('landing.yourDistributorDescription')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
          <Button
            variant="contained"
            href={`mailto:info@bingebox.nl?subject=BingeBox - Information Request`}
            rel="noopener noreferrer"
            target="_top"
          >
            {t('landing.requestMoreInformation')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default InfoBox;

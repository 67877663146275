import React from 'react';
import { AuthContext } from '../../contexts';
import { Box, Typography, Button } from '@mui/material';
import { paymentsServiceUrl } from '../../urls';
import { t } from 'i18next';
import { useFetchChannelByIdQuery } from '../../store';

interface PurchaseChannelProps {
  channelId: string;
}

export function PurchaseChannel(props: PurchaseChannelProps) {
  const authContext = React.useContext(AuthContext);

  const { data } = useFetchChannelByIdQuery({
    id: props.channelId,
    token: authContext.token,
  });

  const checkoutChannel = async (event: any, channelId: string) => {
    event.preventDefault();
    const response = await fetch(`${paymentsServiceUrl}/checkout`, {
      mode: 'cors',
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authContext.token}`,
      },
      body: JSON.stringify({ channelId: channelId }),
    });

    const jsonData: any = await response.json();
    document.location.href = jsonData.redirectUrl;
  };

  return (
    <Box>
      <Typography sx={{ color: 'gray', fontSize: '20px' }}>
        {t('watchContent.onlyAvailableForBuyers')}
      </Typography>
      <Typography sx={{ color: 'gray', fontSize: '12px' }}>
        {t('watchContent.purchaseDescription')}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#9a76a338',
          p: { xs: 2, sm: 6, xl: 8 },
          mt: 6,
          minWidth: 600,
          display: 'inline-block',
        }}
      >
        <Typography sx={{ color: 'white' }} variant="h4">
          {data?.title}
        </Typography>
        <Typography sx={{ color: 'white' }} variant="subtitle2">
          {data?.description}
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{ mt: 3, color: 'white', backgroundColor: 'gray' }}
          onClick={(e) => checkoutChannel(e, props.channelId)}
        >
          {t('subscription.purchase')}
        </Button>
      </Box>
    </Box>
  );
}

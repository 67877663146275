import React from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Content } from '../../../../../types/Content';

interface ContentCardProps {
  contentType: string;
  content: Content;
}

export function ContentCard(props: ContentCardProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/details/' + props.contentType + '/' + props.content._id);
  };

  /**
   * Posters come from a central location, that is BunnyCDN, a timestamp is added
   * to assist with caching, given that this image is prone to change.
   */
  const getPosterUrl = (contentId?: string) => {
    const currentTime = `${Date.now()}`;
    const portersStore = 'https://bingebox-public.b-cdn.net/posters';
    if (!contentId) {
      return `${portersStore}/generic-poster.png?${currentTime}`;
    }
    return `${portersStore}/${contentId}?${currentTime}`;
  };

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = getPosterUrl();
  };

  // 27x40 proportion is the industry size for movie posters
  return (
    <Box
      style={{
        border: '0px solid',
        display: 'inline-block',
        margin: '0 auto',
        width: '240px',
        userSelect: 'none',
      }}
    >
      <Box component={ButtonBase} onClick={handleClick}>
        <img
          alt="thumbnail"
          src={getPosterUrl(props.content._id)}
          height="355px"
          width="240px"
          style={{ margin: 0, padding: 0 }}
          onError={handleImageError}
        />
      </Box>
      <Box style={{ textAlign: 'left', marginTop: 10 }}>
        <Typography variant="body2" color="whitesmoke">
          {props.content.title} ({props.content.year})
        </Typography>
      </Box>
    </Box>
  );
}

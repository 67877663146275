import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaImdb } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdvisoryIcons from '../../components/generics/AdvisoryIcons';
import Ratings from '../../components/ratings/Ratings';
import { AuthContext, AuthContextType } from '../../contexts';
import { Content } from '../../types/Content';
import { Genre } from '../../types/Genre';
import { genreServiceUrl, movieServiceUrl, serieServiceUrl } from '../../urls';
import { ChannelText } from './components/ChannelText';
import SeasonsModalButton from './components/SeasonsModalButton';
import SubtitleButton from './components/SubtitleButton';
import { WatchLaterButton } from './components/WatchLaterButton';
import CustomPlayer from '../../components/generics/CustomPlayer';

interface ContentDetailsProps {
  contentType: string;
}

export function ContentDetails(props: ContentDetailsProps) {
  const { t } = useTranslation();
  const [content, setContent] = React.useState<Content | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [openTrailer, setOpenTrailer] = React.useState(false);
  const [genres, setGenres] = React.useState<Genre[]>();
  const [video, setVideo] = React.useState<any>();
  const authContext = useContext(AuthContext);
  const { contentId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getGenresMetadata = async (authContext: AuthContextType) => {
      const response = await fetch(genreServiceUrl, {
        mode: 'cors',
        headers: { Authorization: `Bearer ${authContext.token}` },
      });
      const jsonData: Genre[] = await response.json();
      setGenres(jsonData);
    };

    const getMovieMetadata = async (
      movieId: string | undefined,
      authContext: AuthContextType,
    ) => {
      const movieMetadata = await fetch(movieServiceUrl + '/' + movieId, {
        mode: 'cors',
        headers: { Authorization: `Bearer ${authContext.token}` },
      });
      const jsonMovie: Content = await movieMetadata.json();
      setContent(jsonMovie);

      const videoMetadata = await fetch(
        movieServiceUrl + '/' + movieId + '/video',
        {
          mode: 'cors',
          headers: { Authorization: `Bearer ${authContext.token}` },
        },
      );
      const jsonVideo = await videoMetadata.json();
      setVideo(jsonVideo);

      setIsLoading(false);
    };

    const getSerieMetadata = async (
      serieId: string | undefined,
      authContext: AuthContextType,
    ) => {
      const response = await fetch(serieServiceUrl + '/' + serieId, {
        mode: 'cors',
        headers: { Authorization: `Bearer ${authContext.token}` },
      });
      const jsonData: Content = await response.json();
      setContent(jsonData);
      setIsLoading(false);
    };

    getGenresMetadata(authContext);

    if (props.contentType === 'movie') {
      getMovieMetadata(contentId, authContext);
    }

    if (props.contentType === 'series') {
      getSerieMetadata(contentId, authContext);
    }
  }, [props, contentId, authContext, setIsLoading, setGenres, setContent]);

  const separator = (
    <Box
      component="span"
      sx={{
        display: 'inline-block',
        mx: '8px',
        transform: 'scale(1.2)',
        color: '#ceaadd',
      }}
    >
      •
    </Box>
  );

  const renderGenreList = () => {
    let genreList: string[] = [];
    content?.genreIds.forEach((genreId) => {
      genres?.forEach((genre) => {
        if (genreId === genre._id) {
          genreList.push(t(`genres.${genre.title}`));
        }
      });
    });

    return <span>{genreList.join(' • ')}</span>;
  };

  const goBack = () => {
    navigate(-1);
  };

  const renderImdbLink = () => {
    if (content?.imdbUrl) {
      /** Open IMDb on a new tab */
      return (
        <Link
          rel="noopener noreferrer"
          target="_blank"
          to={content.imdbUrl}
          style={{ textDecoration: 'none' }}
        >
          <Typography variant="h3" sx={{ color: '#f5c518' }}>
            <ButtonBase>
              <FaImdb />
            </ButtonBase>
          </Typography>
        </Link>
      );
    }
  };

  const renderYear = () => {
    if (content?.yearFinale) {
      return (
        <span>
          {content.year}-{content.yearFinale}
        </span>
      );
    }
    return <span>{content?.year}</span>;
  };

  /**
   * Posters come from a central location, that is BunnyCDN, a timestamp is added
   * to assist with caching, given that this image is prone to change.
   */
  const getPosterUrl = (contentId?: string) => {
    const currentTime = `${Date.now()}`;
    const portersStore = 'https://bingebox-public.b-cdn.net/posters';
    if (!contentId) {
      return `${portersStore}/generic-poster.png?${currentTime}`;
    }
    return `${portersStore}/${contentId}?${currentTime}`;
  };

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = getPosterUrl();
  };

  const displayDuration = () => {
    if (props.contentType === 'movie') {
      return (
        <>
          {Math.round(video.length / 60)} min
          {separator}
        </>
      );
    }
  };

  const displaySubtitle = () => {
    if (props.contentType === 'movie') {
      return <SubtitleButton content={video} variant="normal" />;
    }
  };

  const displayTrailerButton = () => {
    const handleClickOpen = () => {
      setOpenTrailer(true);
    };

    const handleClose = () => {
      setOpenTrailer(false);
    };

    if (content?.trailerVideoId) {
      return (
        <>
          <Button
            sx={{ ml: 0, mr: 1, mb: 1 }}
            variant="contained"
            onClick={handleClickOpen}
          >
            {t('contentDetails.watchTrailer')}
          </Button>
          <Dialog open={openTrailer} onClose={handleClose} maxWidth="xl">
            <Box sx={{ backgroundColor: '#664a6c' }}>
              <DialogContent
                sx={{
                  width: { xs: 300, sm: 640, lg: 1300 },
                  height: { xs: 170, sm: 380, lg: 730 },
                }}
              >
                <CustomPlayer
                  contentId={content._id}
                  contentType={`${props.contentType}Trailer`}
                />
              </DialogContent>
              <DialogActions sx={{ mt: 2, mr: 2, mb: 1 }}>
                <Button onClick={handleClose} autoFocus variant="contained">
                  Close
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </>
      );
    }
  };

  return (
    <Box px={{ xl: 30, lg: 10 }}>
      {isLoading ? (
        <Box id="spinner">
          <CircularProgress
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
            }}
          />
        </Box>
      ) : (
        <Grid
          id="content-details"
          sx={{ mt: 2 }}
          container
          columnSpacing={{ xs: 0, sm: 2 }}
        >
          <Grid item xs={12} sm={5} sx={{ mt: { xs: 3, sm: 7 }, mb: 6 }}>
            <Paper elevation={10} sx={{ mx: 4 }}>
              <img
                alt={content?.title}
                className="fixed-image"
                src={getPosterUrl(content?._id)}
                onError={handleImageError}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            container
            justifyContent="flex-start"
            alignItems="flext-start"
            direction="column"
            rowSpacing={{ xs: 0 }}
            pt={{ sm: 6, xs: 0 }}
            sx={{ px: 3 }}
          >
            <Grid item container sx={{ position: 'relative' }}>
              <Grid item xs={9} sx={{ pr: 5 }}>
                <Typography
                  gutterBottom
                  variant="h4"
                  sx={{ color: 'white', mb: 0, pb: 0 }}
                >
                  {content?.title} ({renderYear()})
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'whitesmoke', mt: -0.3, mb: 0.5 }}
                  pr={{ xl: 30, lg: 10, md: 10 }}
                >
                  {renderGenreList()}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ float: 'right' }}>
                  <Button
                    variant="contained"
                    onClick={goBack}
                    startIcon={<ArrowBack />}
                  >
                    {t('common.back')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Typography variant="h6" gutterBottom sx={{ color: '#aaaaaa' }}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {displayDuration()}
                  {content?.languages.join(' | ')}
                  {displaySubtitle()}
                  {separator}
                  {<ChannelText channelId={content!.channelId} />}
                </Box>
                <Box sx={{ mt: 1 }}>
                  <AdvisoryIcons advisory={content?.advisory} />
                </Box>
                <Box sx={{ mt: 1, mb: 4 }}>
                  <Ratings
                    contentId={contentId!}
                    contentType={props.contentType}
                  />
                </Box>
              </Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography
                variant="body2"
                sx={{ color: 'white' }}
                pr={{ xl: 30, lg: 10, md: 10 }}
              >
                {content?.description}
              </Typography>
            </Box>
            <Box display={'flex'} sx={{ mt: 1 }}>
              {renderImdbLink()}
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography sx={{ paddingTop: 2, color: '#aaaaaa' }}>
                {t('common.directedBy')} {content?.directors.join(' • ')}
              </Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography sx={{ fontSize: 14, color: '#aaaaaa' }}>
                {content?.cast.join(' • ')}
              </Typography>
            </Box>
            <Grid item container sx={{ pt: 3, pb: 5 }} xs={'auto'}>
              <Grid item xs={12}>
                {(() => {
                  if (props.contentType === 'movie') {
                    return (
                      <Link
                        to={'/watch/' + props.contentType + '/' + content?._id}
                      >
                        <Button
                          variant="contained"
                          sx={{ ml: 0, mb: 1, mr: 1 }}
                        >
                          {t('contentDetails.startWatching')}
                        </Button>
                      </Link>
                    );
                  }
                  if (props.contentType === 'series') {
                    return (
                      <SeasonsModalButton
                        seriesId={content?._id ? content?._id : ''}
                      />
                    );
                  }
                  return null;
                })()}
                {displayTrailerButton()}
                <WatchLaterButton
                  contentId={content?._id}
                  contentType={props.contentType}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

import { Box, Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext, AuthContextType } from '../../../contexts';
import { Episode } from '../../../types/Episode';
import { Season } from '../../../types/Season';
import { serieServiceUrl } from '../../../urls';
import { EpisodeCard } from './EpisodeCard';

interface EpisodeNavigationProps {
  episodes: Episode[];
  seriesId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface SeasonBoxProps {
  serieId: string;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function EpisodeNavigation(props: EpisodeNavigationProps) {
  const getLgSize = (n: number) => {
    if (n < 5) {
      return 3;
    } else {
      return 2.3;
    }
  };

  return (
    <Box sx={{ pl: 3, pr: 3 }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        {props.episodes?.map((episode) => (
          <Grid
            key={episode._id}
            item
            xs={12}
            sm={6}
            md={3.4}
            lg={getLgSize(props.episodes.length)}
          >
            <Box sx={{ ml: 'auto', mr: 'auto', width: 230 }}>
              <EpisodeCard seriesId={props.seriesId} episode={episode} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export function SeasonsBox(props: SeasonBoxProps) {
  const { t } = useTranslation();
  const [seasons, setSeasons] = React.useState<Season[] | null>(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const authContext = useContext(AuthContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    getSeasons(props.serieId, authContext);
  }, [props, authContext]);

  const getSeasons = async (serieId: string, authContext: AuthContextType) => {
    const response = await fetch(serieServiceUrl + '/' + serieId, {
      mode: 'cors',
      headers: { Authorization: `Bearer ${authContext.token}` },
    });
    const { seasons } = await response.json();
    setSeasons(seasons);
    setTabIndex(seasons[0].number);
    setIsLoading(false);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="seasons-tab"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {seasons?.map((season) => {
            return (
              <Tab
                key={season.number}
                value={season.number}
                label={
                  t('contentDetails.season', { count: 1 }) + ' ' + season.number
                }
                {...a11yProps(season.number)}
              />
            );
          })}
        </Tabs>
      </Box>
      {seasons?.map((season) => (
        <CustomTabPanel
          key={season.number}
          value={tabIndex}
          index={season.number}
        >
          <EpisodeNavigation
            seriesId={props.serieId}
            episodes={season.episodes}
          />
        </CustomTabPanel>
      ))}
    </Box>
  );
}

import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  styled,
} from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import { ChatBubbleOutline, Close, Send } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { feedbacksServiceUrl } from '../../../../urls';
import { FeedbackType } from '../../../../types/Feedback';
import './ContactUsSideButton.css';
import { AuthContext } from '../../../../contexts';

interface ContactUsSideButtonProps {}

function ContactUsSideButton(props: ContactUsSideButtonProps) {
  const { t } = useTranslation();
  const authContext = React.useContext(AuthContext);
  const [feedback, setFeedback] = React.useState<string>();
  const [open, setOpen] = React.useState(false);
  const [openFeedbackAlert, setOpenFeedbackAlert] = React.useState(false);

  const CustomRippleButtom = styled(Button)<ButtonProps>(({ theme }) => ({
    '& .MuiTouchRipple-child': {
      backgroundColor: '#ab4772 !important',
    },
  }));

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    sendFeedback({
      feedback: feedback ? feedback : '',
      sourceUrl: event.target.baseURI,
      userId: authContext.email ? authContext.email : '',
    });
    setOpen(false);
  };

  const sendFeedback = async (feedback: FeedbackType) => {
    const response = await fetch(feedbacksServiceUrl, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authContext.token}`,
      },
      body: JSON.stringify(feedback),
    });

    if (response.status === 201) {
      setOpenFeedbackAlert(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        id="alert"
        sx={{ width: '100%', position: 'fixed', bottom: 0, px: 20 }}
      >
        <Collapse in={openFeedbackAlert}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenFeedbackAlert(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2, borderRadius: 0 }}
          >
            {t('topMenu.thanksForYourFeedback')}
          </Alert>
        </Collapse>
      </Box>
      <div className="popup-btn">
        <form onSubmit={handleFormSubmit}>
          <CustomRippleButtom
            endIcon={<ChatBubbleOutline />}
            onClick={handleClickOpen}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#9a76a3',
              px: 2,
              py: 1,
              display: 'flex',
            }}
          >
            {t('topMenu.contactUs')}
          </CustomRippleButtom>
          <Dialog maxWidth={'sm'} open={open} onClose={handleClose}>
            <DialogTitle>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {t('topMenu.contactUsTitle')}
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('topMenu.contactUsDescription')}
              </DialogContentText>
              <TextField
                id="feedback"
                margin="dense"
                type="text"
                multiline
                fullWidth
                sx={{ mt: 3 }}
                rows={4}
                variant="filled"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFeedback(event.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                sx={{ mr: 2, mb: 2 }}
                variant="contained"
                onClick={handleFormSubmit}
                startIcon={<Send />}
              >
                {t('common.send')}
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
    </Box>
  );
}

export default ContactUsSideButton;

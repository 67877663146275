import React from 'react';
import { Grid } from '@mui/material';
import { Content } from '../../../types/Content';
import { t } from 'i18next';

interface MovieMetadataProps {
  content: Content;
}

export function MovieMetadata(props: MovieMetadataProps) {
  return (
    <Grid
      item
      container
      spacing={1}
      direction={'column'}
      sx={{ color: 'gray', fontSize: '10px' }}
    >
      <Grid item>
        <span style={{ marginRight: 14 }}>
          {t('common.directedBy')} {props.content.directors.join(' • ')}.
        </span>
      </Grid>

      <Grid item>
        <span style={{ marginRight: 14 }}>
          {t('common.availableIn')} {props.content.languages.join(', ')}.
        </span>
      </Grid>

      <Grid item>
        <span style={{ marginRight: 14 }}>
          {t('common.releaseYear')}: {props.content.year}.
        </span>
      </Grid>

      <Grid item>
        <span style={{ marginRight: 14 }}>
          {t('common.viewOn')} <a href={props.content.imdbUrl}>IMDb</a>.
        </span>
      </Grid>
    </Grid>
  );
}

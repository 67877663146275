import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { watchlaterServiceUrl } from '../../urls';
import { AuthContext } from '../../contexts';
import WatchListItem from './components/WatchListItem';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { useTranslation } from 'react-i18next';
import { WatchLaterType } from '../../types/WatchLater';

function WatchLater() {
  const { t } = useTranslation();
  const authContext = React.useContext(AuthContext);
  const [watchLater, setWatchLater] = React.useState<WatchLaterType[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchUserWatchLaterList = async () => {
      const response = await fetch(watchlaterServiceUrl, {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${authContext.token}`,
        },
      });
      const jsonData: WatchLaterType[] = await response.json();
      setWatchLater(jsonData);
      setIsLoading(false);
    };

    fetchUserWatchLaterList();
  }, [authContext]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Box width="100%" sx={{ pt: 4 }}>
      <Grid container maxWidth={'md'} sx={{ margin: '0 auto' }}>
        <Grid item width={1}>
          <Typography variant="h5" color="whitesmoke" sx={{ mb: 2, ml: 2 }}>
            {watchLater.length >= 1 ? t('watchlater.title') : ''}
          </Typography>
        </Grid>
        {watchLater.map((wl) => {
          return <WatchListItem key={wl.contentId} watchlater={wl} />;
        })}
      </Grid>
      {watchLater.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 10,
          }}
        >
          <BookmarksIcon sx={{ fontSize: 100, color: '#bfacbf', mb: 2 }} />
          <Typography color="whitesmoke">{t('watchlater.empty')}</Typography>
          <Typography color="white" variant="caption" sx={{ mt: 1 }}>
            {t('watchlater.emptyDescription')}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
export default WatchLater;

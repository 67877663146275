import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { Bookmark, Logout, Settings } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../i18n';

function UserIconWithMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signOut } = useAuthenticator((context) => [context.user]);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleSignOut = () => {
    signOut();
    navigate('/');
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title={null}>
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{
            p: 0,
            borderRadius: 0,
            '& .MuiTouchRipple-child': {
              borderRadius: 'inherit',
            },
          }}
        >
          <Avatar
            sx={{
              borderRadius: 0,
              height: { xs: 56, sm: 69 },
              width: { xs: 56, sm: 69 },
            }}
            src=""
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5,
              overflow: 'visible',
              backgroundColor: '#efe1f7',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 29,
                width: 10,
                height: 10,
                bgcolor: '#efe1f7',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Link
          to="/watchlater"
          style={{ textDecoration: 'inherit', color: 'inherit' }}
        >
          <MenuItem
            component="span"
            key="watchlater"
            onClick={handleCloseUserMenu}
          >
            <ListItemIcon>
              <Bookmark fontSize="small" />
            </ListItemIcon>
            {t('topMenu.watchlist')}
          </MenuItem>
        </Link>
        <Link
          to="/home/profile"
          style={{ textDecoration: 'inherit', color: 'inherit' }}
        >
          <MenuItem
            component="span"
            key="settings"
            onClick={handleCloseUserMenu}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {t('topMenu.settings')}
          </MenuItem>
        </Link>

        <MenuItem component="a" key="logout" onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('topMenu.logout')}
        </MenuItem>

        <MenuItem
          component="div"
          key="language"
          sx={{
            ':hover': { backgroundColor: 'inherit', cursor: 'inherit' },
            pt: 2,
            width: 180,
            display: 'flex',
            alignContent: 'flex-start',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
          disableRipple
        >
          <Typography
            variant="caption"
            sx={{ color: '#474748', width: '100%', mb: 1 }}
          >
            {t('common.language')}
          </Typography>
          <Button
            sx={{
              mr: 0.5,
              mb: 0.5,
              backgroundColor: i18n.language === 'en' ? 'white' : '#d3c8d5',
              ':hover': { backgroundColor: '#c6b8c9' },
            }}
            onClick={() => {
              i18n.changeLanguage('en');
            }}
          >
            <span className="fi fi-gb" style={{ marginRight: 3 }} />
            <span style={{ fontFamily: 'Lexend', color: '#6e6771' }}>EN</span>
          </Button>
          <Button
            sx={{
              mr: 0.5,
              mb: 0.5,
              backgroundColor: i18n.language === 'nl' ? 'white' : '#d3c8d5',
              ':hover': { backgroundColor: '#c6b8c9' },
            }}
            onClick={() => {
              i18n.changeLanguage('nl');
            }}
          >
            <span className="fi fi-nl" style={{ marginRight: 3 }} />
            <span style={{ fontFamily: 'Lexend', color: '#6e6771' }}>NL</span>
          </Button>
          <Button
            sx={{
              mr: 0.5,
              backgroundColor: i18n.language === 'pt' ? 'white' : '#d3c8d5',
              ':hover': { backgroundColor: '#c6b8c9' },
            }}
            onClick={() => {
              i18n.changeLanguage('pt');
            }}
          >
            <span className="fi fi-pt" style={{ marginRight: 3 }} />
            <span style={{ fontFamily: 'Lexend', color: '#6e6771' }}>PT</span>
          </Button>
          <Button
            sx={{
              mr: 0.5,
              backgroundColor: i18n.language === 'es' ? 'white' : '#d3c8d5',
              ':hover': { backgroundColor: '#c6b8c9' },
            }}
            onClick={() => {
              i18n.changeLanguage('es');
            }}
          >
            <span className="fi fi-es" style={{ marginRight: 3 }} />
            <span style={{ fontFamily: 'Lexend', color: '#6e6771' }}>ES</span>
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  );
}
export default UserIconWithMenu;

import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Content } from '../../../types/Content';

interface EpisodeMetadataProps {
  episode: Content;
}

export function EpisodeMetadata(props: EpisodeMetadataProps) {
  return (
    <Grid
      item
      container
      spacing={1}
      direction={'column'}
      sx={{ color: 'gray', fontSize: '10px' }}
    >
      <Grid item>
        <span style={{ marginRight: 14 }}>
          {t('common.directedBy')} {props.episode.directors.join(' • ')}.
        </span>
      </Grid>

      <Grid item>
        <span style={{ marginRight: 14 }}>
          {t('common.availableIn')} {props.episode.languages.join(', ')}.
        </span>
      </Grid>
    </Grid>
  );
}

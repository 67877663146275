import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const ratingsApi = createApi({
  reducerPath: 'ratings',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.bingebox.nl',
  }),
  tagTypes: ['Rating'],
  endpoints(builder) {
    return {
      fetchContentRating: builder.query<any, any>({
        providesTags: ['Rating'],
        query: ({ contentType, contentId, token }) => {
          return {
            url: `/ratings/${contentType}/${contentId}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      fetchUserContentRating: builder.query<any, any>({
        providesTags: ['Rating'],
        query: ({ contentType, contentId, token }) => {
          return {
            url: `/ratings/${contentType}/${contentId}/user`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      rateContent: builder.mutation({
        invalidatesTags: ['Rating'],
        query: ({ contentType, contentId, rating, token }) => {
          return {
            url: `/ratings/${contentType}/${contentId}`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              rating: rating,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchContentRatingQuery,
  useFetchUserContentRatingQuery,
  useRateContentMutation,
} = ratingsApi;
export { ratingsApi };

import {
  Box,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Skeleton,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext, AuthContextType } from '../../../contexts';
import { Episode } from '../../../types/Episode';
import { serieServiceUrl } from '../../../urls';
import SubtitleButton from './SubtitleButton';
import { EpisodePreview } from './EpisodePreview';

interface EpisodeCardProps {
  episode: Episode;
  seriesId: string;
}

export function EpisodeCard(props: EpisodeCardProps) {
  const authContext = useContext(AuthContext);
  const [video, setVideo] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    /**
     * Fetch video metadata from BunnyCDN.
     */
    const getVideoMetadata = async (
      serieId: string,
      episodeId: string,
      authContext: AuthContextType,
    ) => {
      const response = await fetch(
        serieServiceUrl + '/' + serieId + '/episodes/' + episodeId + '/video',
        {
          mode: 'cors',
          headers: { Authorization: `Bearer ${authContext.token}` },
        },
      );
      const jsonVideoMetadata = await response.json();
      setVideo(jsonVideoMetadata);
      setIsLoading(false);
    };

    getVideoMetadata(props.seriesId, props.episode._id, authContext);
  }, [props, authContext]);

  const separator = (
    <Box
      component="span"
      sx={{
        display: 'inline-block',
        mx: '2px',
        transform: 'scale(1.2)',
        color: '#ceaadd',
      }}
    >
      •
    </Box>
  );

  const displayDuration = () => {
    return (
      <Chip
        sx={{ ml: 1, fontSize: 12, color: 'gray' }}
        size="small"
        label={Math.round(video.length / 60) + ' min'}
      />
    );
  };

  if (isLoading) {
    /**
     * When loading, display Skeleton:
     */
    return (
      <Card sx={{ backgroundColor: '#462c46', width: 230, height: 200 }}>
        <CardContent sx={{ pt: 1, mb: 0, pb: '8px' }}>
          <Skeleton animation="wave" width={120} height={30} />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={192}
            height={108}
          />
        </CardContent>
        <CardActions sx={{ pt: 0 }}>
          <Grid container spacing={0}>
            <Grid item>
              <Skeleton
                animation="wave"
                width={40}
                height={30}
                sx={{ ml: 1 }}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    );
  }

  return (
    <Card sx={{ backgroundColor: '#462c46', width: 230, height: 200 }}>
      <CardContent sx={{ pt: 1, mb: 0, pb: '8px' }}>
        <Link
          to={'/watch/series/' + props.episode._id}
          style={{
            textDecoration: 'none',
            textTransform: 'inherit',
            color: 'white',
            fontSize: 12,
          }}
        >
          {props.episode.number} {separator} {props.episode.title}
        </Link>
        <Box sx={{ width: 192, height: 108, margin: 'auto', pt: 1 }}>
          <ButtonBase>
            <Link to={'/watch/series/' + props.episode._id}>
              <EpisodePreview
                videoId={video.guid}
                thumbnailFileName={video.thumbnailFileName}
              />
            </Link>
          </ButtonBase>
        </Box>
      </CardContent>
      <CardActions sx={{ pt: 1 }}>
        <Grid container spacing={0}>
          <Grid item>{displayDuration()}</Grid>
          <Grid item>
            <SubtitleButton content={video} variant="small" />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

import * as React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import dayjs from 'dayjs';
import { AuthContext, AuthContextType } from '../../../../contexts';
import { userServiceUrl } from '../../../../urls';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import getCountriesCode, {
  CountryType,
  getCountryName,
} from '../../../../countries';
import { Profile } from '../../../../types/Profile';

const countriesList = getCountriesCode(i18n.language);

function UserSettings() {
  const { t } = useTranslation();
  const authContext = React.useContext(AuthContext);
  const [profileUpdated, setProfileUpdated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<Profile>({
    email: authContext.email,
    name: '',
    dateOfBirth: new Date('01-01-1960'),
    streetComplement: '',
    city: '',
    street: '',
    country: '',
    postCode: '',
  });
  const [country, setCountry] = React.useState<CountryType | null>(
    countriesList[0],
  );
  const [countryInputValue, setCountryInputValue] = React.useState<string>('');

  React.useEffect(() => {
    getProfile(authContext);
  }, [authContext]);

  const getProfile = async (authContext: AuthContextType) => {
    const response = await fetch(userServiceUrl, {
      mode: 'cors',
      headers: { Authorization: `Bearer ${authContext.token}` },
    });
    const jsonData: Profile = await response.json();
    setProfile(jsonData);
    setCountry({
      code: jsonData.country!,
      label: getCountryName(i18n.language, jsonData.country!),
    });
    setIsLoading(false);
  };

  const sendProfileMetadata = async (profile: Profile | undefined) => {
    const response = await fetch(userServiceUrl, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authContext.token}`,
      },
      body: JSON.stringify(profile),
    });

    if (response.status === 200) {
      setProfileUpdated(true);
    }
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    sendProfileMetadata(profile);
  };

  if (isLoading) {
    return <>loading...</>;
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Typography variant="body2">{t('personalSettings.title')}</Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 4 }}>
        {t('personalSettings.control')}
      </Typography>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={8} sx={{ mt: -1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={t('common.name')}
            value={profile.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProfile({ ...profile, name: event.target.value });
            }}
            name="name"
            autoComplete="name"
            autoFocus
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ mt: -1, mb: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(value) => {
                setProfile({ ...profile, dateOfBirth: dayjs(value) });
              }}
              value={dayjs(profile?.dateOfBirth)}
              format="DD-MM-YYYY"
              sx={{ mt: '14px', width: '100%', backgroundColor: 'white' }}
              label={t('common.birthdate')}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={9} sx={{ mt: -1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="street"
            label={t('common.street')}
            name="street"
            value={profile.street}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProfile({ ...profile, street: event.target.value });
            }}
            autoComplete="street"
            autoFocus
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} sm={3} sx={{ mt: -1 }}>
          <TextField
            margin="normal"
            required
            id="number"
            label={t('common.houseNumber')}
            name="number"
            autoComplete="number"
            value={profile.streetComplement}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProfile({ ...profile, streetComplement: event.target.value });
            }}
            autoFocus
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} sm={5} sx={{ mt: -1 }}>
          <TextField
            margin="normal"
            required
            id="city"
            fullWidth
            label={t('common.city')}
            name="city"
            autoComplete="city"
            value={profile.city}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProfile({ ...profile, city: event.target.value });
            }}
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} sm={3} sx={{ mt: -1 }}>
          <TextField
            margin="normal"
            required
            id="postcode"
            label={t('common.postcode')}
            name="postcode"
            value={profile.postCode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProfile({ ...profile, postCode: event.target.value });
            }}
            autoComplete="postcode"
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} sm={4} sx={{ mt: -1 }}>
          <Autocomplete
            value={country}
            onChange={(event: any, newValue: CountryType | null) => {
              setCountry(newValue);
              setProfile({
                ...profile,
                country: newValue?.code || '',
              });
            }}
            inputValue={countryInputValue}
            onInputChange={(event, newInputValue: string) => {
              setCountryInputValue(newInputValue);
            }}
            id="country-select-demo"
            sx={{ mt: 2 }}
            options={countriesList}
            autoHighlight
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('common.country')}
                value={profile.country}
                sx={{ backgroundColor: 'white' }}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography variant="caption" sx={{ mt: 4 }}>
        🔒 {t('personalSettings.privacyInfo')}
      </Typography>
      {profileUpdated ? (
        <Typography variant="body2" sx={{ mt: 1, color: 'green' }}>
          {t('personalSettings.profileUpdated')}
        </Typography>
      ) : (
        <></>
      )}
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 4, mb: 4, float: 'right' }}
      >
        {t('common.save')}
      </Button>
    </form>
  );
}

export default UserSettings;

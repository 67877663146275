import {
  Box,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import React from 'react';
import { PiSubtitles } from 'react-icons/pi';
import i18n from '../../../i18n';

interface SubtitleButtonProps {
  content: any;
  variant: 'small' | 'normal';
}

export default function SubtitleButton(props: SubtitleButtonProps) {
  const languageNames = new Intl.DisplayNames([i18n.language], {
    type: 'language',
  });

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 260,
      fontSize: theme.typography.pxToRem(12),
      padding: props.variant === 'small' ? 8 : 15,
    },
  }));

  if (props.content?.captions.length > 0) {
    return (
      <Box sx={{ pl: 1 }}>
        <HtmlTooltip
          arrow
          slotProps={{
            arrow: {
              sx: {
                color: 'white',
              },
            },
          }}
          title={
            <React.Fragment>
              <Typography
                color="inherit"
                variant={props.variant === 'small' ? 'body2' : 'body1'}
              >
                {t('contentDetails.subtitlesIn')}:
              </Typography>

              <Typography
                variant={props.variant === 'small' ? 'caption' : 'body2'}
              >
                {props.content.captions
                  .map((c: any) => capitalize(languageNames.of(c.label)))
                  .join(', ')}
              </Typography>
            </React.Fragment>
          }
        >
          <IconButton sx={{ p: 0 }} disableRipple>
            <PiSubtitles
              color="white"
              size={props.variant === 'small' ? '15px' : '20px'}
            />
          </IconButton>
        </HtmlTooltip>
      </Box>
    );
  }

  return <></>;
}

import * as React from 'react';
import { useFetchUserChannelsQuery } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../contexts';
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { Tv } from '@mui/icons-material';

function ChannelSettings() {
  const { t } = useTranslation();
  const context = React.useContext(AuthContext);
  const { data, isLoading } = useFetchUserChannelsQuery(context.token);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="body2">
        {t('channelSettings.explanation')}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        {t('channelSettings.description')}
      </Typography>

      <List dense={true} sx={{ mt: 2, mb: 3 }}>
        {data?.map((channel) => (
          <ListItem key={channel.title}>
            <ListItemAvatar>
              <Avatar>
                <Tv />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${channel.title} - ${channel.description}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default ChannelSettings;

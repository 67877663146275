import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import './Landing.css';
import InfoBox from './components/InfoBox';
import BackgroundSvg from './components/BackgroundSvg';
import Footer from './components/Footer';

function Landing() {
  const { t } = useTranslation();

  return (
    <Box className="wrapper">
      <Box className="content">
        <Grid
          container
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ pt: 4, px: { xs: 2, sm: 10, md: 15, lg: 20, xl: 35 } }}
        >
          <Grid item sx={{ width: 220 }}>
            <img src="/logo.png" alt="logo" style={{ width: '100%' }} />
          </Grid>
          <Grid
            item
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Button href="/sign-in" color="secondary" sx={{ px: 2, mb: 2 }}>
              {t('common.signIn')}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ mt: { xs: 3, sm: 2, lg: 0 } }}
          >
            <Button
              size="small"
              sx={{
                backgroundColor:
                  i18n.language === 'en' ? '#ffffffa1' : '#ffffff3d',
                ':hover': { backgroundColor: '#ffffffa1' },
              }}
              onClick={() => {
                i18n.changeLanguage('en');
              }}
            >
              <span className="fi fi-gb" style={{ marginRight: 3 }} />
              <span style={{ fontFamily: 'Lexend', color: '#6e6771' }}>EN</span>
            </Button>
            <Button
              size="small"
              sx={{
                backgroundColor:
                  i18n.language === 'nl' ? '#ffffffa1' : '#ffffff3d',
                ':hover': { backgroundColor: '#ffffffa1' },
              }}
              onClick={() => {
                i18n.changeLanguage('nl');
              }}
            >
              <span className="fi fi-nl" style={{ marginRight: 3 }} />
              <span style={{ fontFamily: 'Lexend', color: '#6e6771' }}>NL</span>
            </Button>
            <Button
              size="small"
              sx={{
                backgroundColor:
                  i18n.language === 'pt' ? '#ffffffa1' : '#ffffff3d',
                ':hover': { backgroundColor: '#ffffffa1' },
              }}
              onClick={() => {
                i18n.changeLanguage('pt');
              }}
            >
              <span className="fi fi-pt" style={{ marginRight: 3 }} />
              <span style={{ fontFamily: 'Lexend', color: '#6e6771' }}>PT</span>
            </Button>
            <Button
              size="small"
              sx={{
                backgroundColor:
                  i18n.language === 'es' ? '#ffffffa1' : '#ffffff3d',
                ':hover': { backgroundColor: '#ffffffa1' },
              }}
              onClick={() => {
                i18n.changeLanguage('es');
              }}
            >
              <span className="fi fi-es" style={{ marginRight: 3 }} />
              <span style={{ fontFamily: 'Lexend', color: '#6e6771' }}>ES</span>
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 8 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="center"
              color={'white'}
              sx={{
                px: { xs: 3, sm: 10, md: 30, lg: 20, xl: 40 },
                mb: { xs: 2, sm: 0 },
              }}
            >
              {t('landing.originalContent')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" color={'white'}>
              {t('landing.unlimitedStreaming')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Typography variant="body1" align="center" color={'white'}>
              {t('landing.startWatching')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              size="large"
              href="/sign-up"
              color="secondary"
              sx={{
                px: 2,
                height: 60,
                width: 350,
                fontSize: 22,
                backgroundColor: '#5e285e',
              }}
            >
              {t('landing.createYourAccount')}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ mt: { xs: 6, sm: 10 }, mb: { xs: 6, sm: 10 } }}
        >
          <Grid item>
            <InfoBox />
          </Grid>
        </Grid>
      </Box>
      <Box className="background">
        <BackgroundSvg />
      </Box>
      <Footer />
    </Box>
  );
}

export default Landing;

import * as React from 'react';
import { Grid } from '@mui/material';
import ProfileAccordion from './components/ProfileAccordion';

function Profile() {
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: 30 }}
    >
      <Grid xs={11} sm={11} md={8} lg={6} xl={5} item>
        <ProfileAccordion />
      </Grid>
    </Grid>
  );
}

export default Profile;

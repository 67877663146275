import { setupListeners } from '@reduxjs/toolkit/query';
import { moviesApi } from './apis/moviesApi';
import { configureStore } from '@reduxjs/toolkit';
import { seriesApi } from './apis/seriesApi';
import { channelsApi } from './apis/channelsApi';
import { ratingsApi } from './apis/ratingsApi';

export const store = configureStore({
  reducer: {
    [moviesApi.reducerPath]: moviesApi.reducer,
    [seriesApi.reducerPath]: seriesApi.reducer,
    [channelsApi.reducerPath]: channelsApi.reducer,
    [ratingsApi.reducerPath]: ratingsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(moviesApi.middleware)
      .concat(seriesApi.middleware)
      .concat(ratingsApi.middleware)
      .concat(channelsApi.middleware);
  },
});

setupListeners(store.dispatch);

export { useFetchMovieByIdQuery } from './apis/moviesApi';
export { useFetchSerieByIdQuery } from './apis/seriesApi';
export {
  useFetchContentRatingQuery,
  useFetchUserContentRatingQuery,
  useRateContentMutation,
} from './apis/ratingsApi';
export {
  useFetchChannelByIdQuery,
  useFetchUserChannelsQuery,
} from './apis/channelsApi';

import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../../contexts';
import { authorizerServiceUrl } from '../../../urls';
import { Skeleton } from '@mui/material';

interface EpisodePreviewProps {
  videoId: string;
  thumbnailFileName: string;
}

export function EpisodePreview(props: EpisodePreviewProps) {
  const authContext = useContext(AuthContext);
  const [token, setToken] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isHovered, setIsHovered] = React.useState(false);

  useEffect(() => {
    const getToken = async () => {
      const response = await fetch(authorizerServiceUrl + '/thumbnail', {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authContext.token}`,
        },
        body: JSON.stringify({
          videoId: props.videoId,
          thumbnailFileName: props.thumbnailFileName,
        }),
      });
      const jsonToken = await response.json();
      setToken(jsonToken);
      setIsLoading(false);
    };
    getToken();
  }, [props, authContext]);

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={192}
        height={108}
      />
    );
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <picture>
          <source height={108} srcSet={token.previewUrl} type="image/webp" />
          <img src={token.previewUrl} height={108} alt="Thumbnail" />
        </picture>
      ) : (
        <img src={token.thumbnailUrl} height={108} alt="Thumbnail" />
      )}
    </div>
  );
}

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function SuccessfulPayment() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      navigate('/home');
    }, 6000);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 10,
      }}
    >
      <CheckCircleOutlineIcon sx={{ fontSize: 100, color: '#8fce8f' }} />
      <Typography variant="h4" color="white">
        {t('successPayment.title')}
      </Typography>
      <Typography color="white">{t('successPayment.details')}</Typography>
      <Typography color="white" variant="caption" sx={{ mt: 2 }}>
        {t('successPayment.redirect')}
      </Typography>
    </Box>
  );
}
export default SuccessfulPayment;

import * as React from 'react';
import { ContentCard } from './ContentCard';
import { Box, Grid, Typography } from '@mui/material';
import './ContentNavigationBox.css';
import { useTranslation } from 'react-i18next';
import { Content } from '../../../../../types/Content';

interface NavigationProps {
  genre: any;
  contents: Content[];
  contentType: string;
}

function ContentNavigationBox(props: NavigationProps) {
  const { t } = useTranslation();

  const renderContent = () => {
    if (!props.contents.length) {
      return (
        <Typography color="white" variant="body2" sx={{ pt: 3 }}>
          {t('contentNavigation.noContentFound')}
        </Typography>
      );
    }

    return props.contents.map((content) => (
      <Grid
        key={content.title}
        item
        xs={6}
        sm={5}
        md={4}
        lg={3}
        xl={2}
        sx={{
          maxWidth: '240px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ContentCard content={content} contentType={props.contentType} />
      </Grid>
    ));
  };

  return (
    <>
      <Box className="contentNavigationBox">
        <Box sx={{ mb: 4, ml: 5 }}>
          <Grid container spacing={1} direction={'row'}>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                sx={{ color: 'white', textTransform: 'capitalize' }}
              >
                {t(`genres.${props.genre.title}`)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 6 }}
        >
          {renderContent()}
        </Grid>
      </Box>
    </>
  );
}
export default ContentNavigationBox;

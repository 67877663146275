import * as React from 'react';
import { Box } from '@mui/material';
import { AdvisoryType } from '../../types/Advisory';

interface AdvisoryIconProps {
  advisory: AdvisoryType | undefined;
}

function AdvisoryIcons(props: AdvisoryIconProps) {
  let age: string = '';
  let explanation: string[] = [];

  if (props.advisory) {
    age = props.advisory.age;
    explanation = props.advisory.explanation;
  }
  return (
    <Box>
      <img
        alt={'age-' + age}
        width="30"
        style={{ marginRight: 4 }}
        src={'/advisory/age/' + age + '.png'}
      />
      {explanation.map((e) => (
        <img
          key={'explanation-' + e}
          alt={'explanation-' + { e }}
          width="30"
          style={{ marginRight: 4 }}
          src={'/advisory/explanation/' + e + '.png'}
        />
      ))}
    </Box>
  );
}

export default AdvisoryIcons;

import * as React from 'react';
import './Home.scss';
import HorizontalNavigation from './horizontalNavigation/HorizontalNavigation';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <HorizontalNavigation
        contentType="movie"
        header={t('home.mostPopularMovies')}
      />
      <HorizontalNavigation
        contentType="series"
        header={t('home.mostPopularSeries')}
      />
    </>
  );
}
export default Home;

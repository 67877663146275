import * as React from 'react';
import { Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Movie, Tv } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function BurgerMenu() {
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box
      maxWidth={48}
      sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
    >
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5,
              overflow: 'visible',
              backgroundColor: '#efe1f7',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 20,
                width: 10,
                height: 10,
                bgcolor: '#efe1f7',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          mt: { xs: 0.5, sm: 1.3 },
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Link
          to="/movie/genres"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <MenuItem component="span" key="movie-genres">
            <ListItemIcon>
              <Movie fontSize="small" />
            </ListItemIcon>
            {t('topMenu.movies')}
          </MenuItem>
        </Link>

        <Link
          to="/series/genres"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <MenuItem component="span" key="series-genres">
            <ListItemIcon>
              <Tv fontSize="small" />
            </ListItemIcon>
            {t('topMenu.series')}
          </MenuItem>
        </Link>
      </Menu>
    </Box>
  );
}
export default BurgerMenu;

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AuthContext } from '../../../../contexts';
import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { userServiceUrl } from '../../../../urls';
import getCountries, { CountryType } from '../../../../countries';
import i18n from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

type Profile = {
  name: string | undefined;
  email: string | undefined | null;
  dateOfBirth: Date | undefined | Dayjs;
  street: string | undefined;
  streetComplement: string | undefined;
  city: string | undefined;
  country: string | null;
  postCode: string | undefined;
};

export default function Wizard() {
  const { t } = useTranslation();
  const authContext = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setProfileUpdated] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [profile, setProfile] = useState<Profile>({
    email: authContext.email,
    name: '',
    dateOfBirth: new Date('01-01-1960'),
    streetComplement: '',
    city: '',
    street: '',
    country: '',
    postCode: '',
  });

  const steps = [t('wizard.yourProfile'), t('wizard.yourPersonalData')];

  const countriesList = getCountries(i18n.language);

  const [country, setCountry] = React.useState<CountryType | null>(
    countriesList[0],
  );
  const [countryInputValue, setCountryInputValue] = React.useState<string>('');

  const sendProfileMetadata = async (profile: Profile | undefined) => {
    setIsLoading(true);
    const response = await fetch(userServiceUrl, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authContext.token}`,
      },
      body: JSON.stringify(profile),
    });

    if (response.status === 201) {
      setProfileUpdated(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(currentUser, {
        profile: 'CREATED',
      });
    }
    setIsLoading(false);
  };

  const isStepOptional = (step: number) => {
    return step === 2;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1) {
      sendProfileMetadata(profile);
    }

    if (activeStep === 0) {
      let fields: string[] = [];

      if (!profile.name) {
        fields.push(t('wizard.name'));
      }
      if (!profile.dateOfBirth) {
        fields.push(t('wizard.dob'));
      }

      if (fields.length) {
        return alert(`${t('wizard.missingFields')}: ${fields}`);
      }
    }

    if (activeStep === 1) {
      let fields: string[] = [];

      if (!profile.city) {
        fields.push(t('wizard.city'));
      }
      if (!profile.street) {
        fields.push(t('wizard.street'));
      }
      if (!profile.country) {
        fields.push(t('wizard.country'));
      }
      if (!profile.postCode) {
        fields.push(t('wizard.postCode'));
      }
      if (!profile.streetComplement) {
        fields.push(t('wizard.number'));
      }

      if (fields.length) {
        return alert(`${t('wizard.missingFields')}: ${fields.join(', ')}.`);
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error(t('wizard.cantSkip'));
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleStart = () => {
    window.location.reload();
  };

  const handleWizardContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Typography>{t('wizard.beforeStart')}</Typography>
            <Box sx={{ m: 2 }} />
            <Grid container spacing={{ xs: 1, sm: 4 }} sx={{ px: 4 }}>
              <Grid id="grid-item-name" xs={12} sm={7} item>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label={t('wizard.name')}
                  value={profile.name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setProfile({ ...profile, name: event.target.value });
                  }}
                  name="name"
                  autoComplete="name"
                  autoFocus
                  variant="outlined"
                  sx={{ backgroundColor: 'white' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid id="grid-item-age" xs={12} sm={5} item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(value) => {
                      setProfile({ ...profile, dateOfBirth: dayjs(value) });
                    }}
                    value={dayjs(profile?.dateOfBirth)}
                    format="DD-MM-YYYY"
                    sx={{ mt: '14px', width: '100%', backgroundColor: 'white' }}
                    label={t('wizard.dob')}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Typography>{t('wizard.comply')}</Typography>
            <Box sx={{ m: 2 }} />

            <Grid container spacing={2} sx={{ px: 4 }}>
              <Grid item xs={12} sm={9}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="street"
                  label={t('wizard.street')}
                  name="street"
                  value={profile.street}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setProfile({ ...profile, street: event.target.value });
                  }}
                  autoComplete="street"
                  autoFocus
                  variant="outlined"
                  sx={{ backgroundColor: 'white' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  margin="normal"
                  required
                  id="number"
                  label={t('wizard.number')}
                  name="number"
                  fullWidth
                  autoComplete="number"
                  value={profile.streetComplement}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setProfile({
                      ...profile,
                      streetComplement: event.target.value,
                    });
                  }}
                  variant="outlined"
                  sx={{ backgroundColor: 'white' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  margin="normal"
                  required
                  id="city"
                  fullWidth
                  label={t('wizard.city')}
                  name="city"
                  autoComplete="city"
                  value={profile.city}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setProfile({ ...profile, city: event.target.value });
                  }}
                  variant="outlined"
                  sx={{ backgroundColor: 'white' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  margin="normal"
                  required
                  id="postcode"
                  label={t('wizard.postCode')}
                  fullWidth
                  name="postcode"
                  value={profile.postCode}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setProfile({ ...profile, postCode: event.target.value });
                  }}
                  autoComplete="postcode"
                  variant="outlined"
                  sx={{ backgroundColor: 'white' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  value={country}
                  onChange={(event: any, newValue: CountryType | null) => {
                    setCountry(newValue);
                    setProfile({
                      ...profile,
                      country: newValue?.code || '',
                    });
                  }}
                  inputValue={countryInputValue}
                  onInputChange={(event, newInputValue: string) => {
                    setCountryInputValue(newInputValue);
                  }}
                  id="country-select-demo"
                  sx={{ mt: 2 }}
                  options={countriesList}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value.code
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={t('wizard.country')}
                      value={profile.country}
                      sx={{ backgroundColor: 'white' }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: 30 }}
    >
      <Grid xs={11} sm={11} md={8} lg={6} xl={5} item>
        <Paper style={{ padding: 20 }}>
          <Typography>{t('wizard.welcome')}</Typography>
          <Box sx={{ mt: { xs: 2 } }} />
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};

              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">
                    {t('wizard.optional')}
                  </Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>
                    <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ mt: { xs: 2 } }} />
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('wizard.allDone')}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleStart} disabled={isLoading}>
                  {t('wizard.begin')}
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {handleWizardContent(activeStep)}
              <Box sx={{ m: 2 }} />
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t('common.back')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    {t('wizard.skip')}
                  </Button>
                )}
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1
                    ? t('wizard.done')
                    : t('wizard.next')}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 10,
      }}
    >
      <PriorityHighIcon sx={{ fontSize: 100, color: '#cba950' }} />
      <Typography variant="h4" color="white">
        {t('notFound.title')}
      </Typography>
      <Typography color="white">{t('notFound.details')}</Typography>
      <Typography color="white" variant="caption" sx={{ mt: 2 }}>
        <Link href="/">{t('notFound.bringeBack')}</Link>
      </Typography>
    </Box>
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ChannelType } from '../../types/Channel';

const channelsApi = createApi({
  reducerPath: 'channels',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.bingebox.nl',
  }),
  endpoints(builder) {
    return {
      fetchChannelById: builder.query<ChannelType, any>({
        query: ({ id, token }) => {
          return {
            url: `/channels/${id}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      fetchUserChannels: builder.query<ChannelType[], any>({
        query: (token) => {
          return {
            url: `/channels/purchased`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
    };
  },
});

export const { useFetchChannelByIdQuery, useFetchUserChannelsQuery } =
  channelsApi;
export { channelsApi };

import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { GenreButton } from './GenreButton';
import './GenreNavigationBox.css';
import { useTranslation } from 'react-i18next';

interface NavigationProps {
  contentType: string;
  genres: Genre[];
}

interface Genre {
  _id: string;
  title: string;
}

function GenreNavigationBox(props: NavigationProps) {
  const { t } = useTranslation();
  const gridRef = React.useRef<HTMLDivElement>(null);

  function mouseMoveEvent(e: any) {
    if (gridRef.current) {
      const { x, y } = gridRef.current.getBoundingClientRect()!;
      gridRef.current.style.setProperty('--x', `${e.clientX - x}`);
      gridRef.current.style.setProperty('--y', `${e.clientY - y}`);
    }
  }

  React.useEffect(() => {
    const refCopy = gridRef;
    if (gridRef) {
      gridRef.current?.addEventListener('mousemove', (e) => mouseMoveEvent(e));
    }

    return () =>
      refCopy.current?.removeEventListener('mousemove', (e) =>
        mouseMoveEvent(e),
      );
  }, [gridRef]);

  return (
    <Box className="genreNavigationBox">
      <Grid
        className="shiny"
        ref={gridRef}
        container
        sx={{ textAlign: 'center', backgroundColor: '#570a5a4d', py: 0.5 }}
      >
        {props.genres.map(({ _id, title }) => (
          <GenreButton
            key={_id}
            title={t(`genres.${title}`)}
            contentType={props.contentType}
            genreId={_id}
            sx={{ zIndex: '1' }}
          />
        ))}
      </Grid>
    </Box>
  );
}
export default GenreNavigationBox;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          common: {
            sponsor: 'Sponsor',
            sponsored: 'Sponsored',
            viewOn: 'View on',
            signIn: 'Sign in',
            watchNow: 'Watch now',
            watchLater: 'Watch later',
            duration: 'Duration',
            minutes: 'minutes',
            directedBy: 'Directed by',
            castMembers: 'Cast members',
            availableIn: 'Available in',
            releaseYear: 'Year of release',
            orOlder: 'and older',
            watchOn: 'Watch on',
            back: 'Back',
            name: 'Name',
            birthdate: 'Date of birth',
            street: 'Street',
            houseNumber: 'Number',
            city: 'City',
            postcode: 'Post code',
            country: 'Country',
            save: 'Save',
            cancel: 'Cancel',
            confirm: 'Confirm',
            more: 'More',
            close: 'Close',
            send: 'Send',
            language: 'Language',
          },
          genres: {
            action: 'Action',
            adventure: 'Adventure',
            comedy: 'Comedy',
            crime: 'Crime',
            drama: 'Drama',
            family: 'Family',
            fantasy: 'Fantasy',
            gameshow: 'Game Show',
            horror: 'Horror',
            kids: 'Kids',
            reality: 'Reality',
            romance: 'Romance',
            scifi: 'Sci-Fi',
            thriller: 'Thriller',
          },
          landing: {
            requestMoreInformation: 'Request more information',
            followUs: 'Follow us on social media',
            addYourContent: 'Would you like to see your content here?',
            originalContent: 'Support your local movie and series creators',
            unlimitedStreaming: 'Unlimited streaming. One time payment.',
            startWatching: 'Start watching now',
            createYourAccount: 'Create your account!',
            yourDistributor:
              'Your distributor for independent movies and series',
            yourDistributorDescription:
              'Self-distributing a film can be a challenging process. BingeBox can support you as your distribution platform with an entirely transparent monetization model.',
          },
          login: {
            loginMessage: 'Login to your account',
            loginConfirm: 'Confirm',
            resetPassword: 'Reset password',
            resetPasswordConfirm: 'Please confirm',
            createAccount: 'Create account',
            backToSignIn: 'Back to Sign In',
            checkYourMail: 'Check your e-mail',
            forms: {
              username: {
                label: 'E-mail',
                placeholder: 'Fill in your e-mail address',
              },
              password: {
                label: 'Password',
                placeholder: 'Fill in your password',
              },
              confirmPassword: {
                label: 'Confirm your password',
                placeholder: 'Repeat your password',
              },
              confirmationCode: {
                label: 'Confirmation code',
                placeholder: 'Fill in your confirmation code',
              },
            },
          },
          wizard: {
            yourProfile: 'Your profile',
            yourPersonalData: 'Your personal data',
            comply:
              'To comply with local regulations, please tell us where you are registering your account below.',
            skip: 'Skip',
            done: 'Done',
            next: 'Next',
            welcome: 'Welcome to BingeBox!',
            optional: 'Optional',
            allDone:
              'All steps completed - thank you for joining BingeBox! Click on BEGIN to start binging!',
            name: 'Name',
            begin: 'Begin',
            dob: 'Date of birth',
            missingFields: 'The following fields are missing',
            city: 'City',
            street: 'Street',
            country: 'Country',
            postCode: 'Post code',
            number: 'Number',
            cantSkip: 'You can not skip a step that is not optional.',
            beforeStart:
              "Before you start, tell us about yourself. Let's start with your name and age.",
          },
          topMenu: {
            movies: 'Movies',
            series: 'Series',
            watchlist: 'Watchlist',
            settings: 'Settings',
            logout: 'Logout',
            thanksForYourFeedback: 'Thank you for your feedback!',
            contactUs: 'Support',
            contactUsTitle: 'Get in touch with us!',
            contactUsDescription:
              'Having issues? Get in touch with us! We will do our best to get back to you as soon as possible.',
          },
          home: {
            mostPopularMovies: 'Most popular movies',
            mostPopularSeries: 'Most popular series',
          },
          contentDetails: {
            subtitlesIn: 'Subtitles available in',
            startWatching: 'Start watching',
            watchTrailer: 'Watch trailer',
            season_one: 'Season',
            season_other: 'Seasons',
          },
          userSettings: {
            accountDetails: 'Account settings',
            userDetails: 'Personal information',
            securityDetails: 'Security settings',
            subscriptionDetails: 'Subscription',
          },
          accountSettings: {
            emailVerified: 'Your e-mail address has been verified.',
            checkYourEmail: 'Please verify your e-mail address!',
            accountRegisteredTo: 'This account is registered to',
            notYourAccountQuestion: 'Not your account?',
            logoutHere: 'Sign out here',
            deleteAccount: 'Delete account',
          },
          securitySettings: {
            passwordUpdated: 'Password updated!',
            changePasswordQuestion: 'Would you like to change your password?',
            provideNewPassword:
              'To do so, provide us with your current password and a new password.',
            currentPassword: 'Current password',
            newPassword: 'New password',
            tooShort: 'too short',
            tooWeak: 'too weak',
            weak: 'weak',
            okay: 'okay',
            good: 'good',
            strong: 'strong',
            updatePassword: 'Update password',
          },
          channelSettings: {
            title: 'Channels',
            description:
              'Below you can find a list with all your purchased channels:',
            explanation:
              'Channels are a way for creators to bundle content together. Once you purchase a channel, you will gain unlimited access to its current content and future releases, such as new movies, series, seasons, episodes, and more.',
          },
          personalSettings: {
            title:
              'Your personal details are necessary for our payment service.',
            control: 'Please check if all the information below is correct.',
            profileUpdated: 'Profile updated',
            privacyInfo:
              "Don't worry! We take your privacy seriously, and your personal information will never be shared with third parties",
          },
          subscription: {
            ending: 'ending',
            subscriptionHistory: 'Subscription history',
            status: 'Status',
            paid: 'Paid',
            yes: 'Yes',
            no: 'No',
            upcoming: 'Upcoming',
            amount: 'Amount',
            paymentMethod: 'Payment method',
            start: 'Start',
            validUntil: 'Valid until',
            upcomingInvoices: 'Upcoming invoices',
            nextPaymentDueOn: 'Next payment due on',
            total: 'Total',
            automaticPayment: 'Automatic payment',
            purchase: 'One time purchase',
            month: 'month',
            year: 'year',
            monthlySubscriptionTitle: 'Monthly pass',
            monthlySubscriptionDescription:
              'With the monthly pass you are granted with unrestricted access to all content without any Ads.',
            yearlySubscriptionTitle: 'Yearly pass',
            yearlySubscriptionDescription:
              'With the yearly pass you support our platform by saving 16%. You will enjoy the same unrestricted access to all content without any Ads.',
            cancelSubscription: 'Cancel subscription',
            areYouSureTitle:
              'Are you sure you want to cancel your subscription?',
            areYouSureDescription:
              'You are free to cancel your subscription at any moment by simply confirming your request below. Once your subscription is cancelled you will still be able to enjoy our content until the end of your current subscription period.',
          },
          successPayment: {
            title: 'Thank you for your purchase!',
            details: 'Your order was completed successfully.',
            redirect:
              'You will be automatically redirected to the main page...',
          },
          abortedPayment: {
            title: 'Ops! Something went wrong...',
            details:
              'Seems like something went wrong with your order, but do not worry, no payment has been processed.',
            redirect:
              'You will be automatically redirected back to your subscription settings...',
          },
          watchContent: {
            purchaseDescription:
              'By purchasing this channel you will gain access to all its current content as well as future releases.',
            onlyAvailableForBuyers: 'Get unlimited access to this channel.',
            clickToSubscribe: 'Click here to subscribe!',
            sponsorTip:
              'This creator will directly benefit from your funding! 💖',
          },
          contentNavigation: {
            noContentFound: 'Apologies, but no content found...',
            selectGenre: 'Select a genre to get started...',
          },
          watchlater: {
            title: 'My watchlist',
            empty: 'List is empty...',
            addedOn: 'Added on',
            removeFromList: 'Remove from watchlist',
            emptyDescription:
              'Here you can find the content you marked to watch later.',
          },
          notFound: {
            title: 'Not found',
            details: 'The page you are looking for could not be found.',
            bringeBack: 'Bring me back home...',
          },
        },
      },
      nl: {
        translation: {
          common: {
            sponsor: 'Sponsor',
            sponsored: 'Sponsored',
            viewOn: 'Meer op',
            watchNow: 'Kijk nu',
            watchLater: 'Kijk later',
            duration: 'Speelduur',
            minutes: 'minuten',
            directedBy: 'Geregisseerd door',
            castMembers: 'Acteurs',
            availableIn: 'Beschikbaar in',
            releaseYear: 'Jaar van uitgave',
            orOlder: 'en ouder',
            watchOn: 'Kijk op',
            back: 'Terug',
            name: 'Naam',
            birthdate: 'Geboortedatum',
            street: 'Straat',
            houseNumber: 'Nummer',
            city: 'Stad',
            postcode: 'Postcode',
            country: 'Land',
            save: 'Opslaan',
            cancel: 'Annuleren',
            confirm: 'Bevestigen',
            more: 'Meer',
            close: 'Sluiten',
            send: 'Verstuur',
            language: 'Taal',
          },
          genres: {
            action: 'Actie',
            adventure: 'Avontuur',
            comedy: 'Komedie',
            crime: 'Misdaad',
            drama: 'Drama',
            family: 'Familie',
            fantasy: 'Fantasie',
            gameshow: 'Spelshow',
            horror: 'Horror',
            kids: 'Kinderen',
            reality: 'Reality',
            romance: 'Romantiek',
            scifi: 'Sci-Fi',
            thriller: 'Thriller',
          },
          landing: {
            requestMoreInformation: 'Vraag meer informatie aan',
            followUs: 'Volg ons op sociale media',
            addYourContent: 'Wilt u uw content hier zien?',
            originalContent: 'Steun lokale film- en serie makers',
            unlimitedStreaming: 'Onbeperkt streamen. Eenmalige betaling.',
            startWatching: 'Begin vandaag nog met kijken',
            createYourAccount: 'Maak een account aan!',
            yourDistributor: 'Uw distributeur voor independent films en series',
            yourDistributorDescription:
              'Het zelf distribueren van een film kan een uitdagend proces zijn. BingeBox kunt u als uw distributieplatform ondersteunen met een volledig transparant model voor het genereren van inkomsten.',
          },
          login: {
            loginMessage: 'Log in op je account',
            loginConfirm: 'Bevestigen',
            resetPassword: 'Wachtwoord resetten',
            resetPasswordConfirm: 'Verifieer uw wachtwoord',
            createAccount: 'Account aanmaken',
            backToSignIn: 'Terug naar Inloggen',
            checkYourMail: 'Controleer je mailbox',
            forms: {
              username: {
                label: 'E-mail',
                placeholder: 'Voer e-mail in',
              },
              password: {
                label: 'Wachtwoord',
                placeholder: 'Voer wachtwoord in',
              },
              confirmPassword: {
                label: 'Bevestig wachtwoord',
                placeholder: 'Herhaal wachtwoord',
              },
              confirmationCode: {
                placeholder: 'Voer code in',
                label: 'Verificatie code',
              },
            },
          },
          wizard: {
            yourProfile: 'Jouw profiel',
            yourPersonalData: 'Uw persoonlijke gegevens',
            comply:
              'Om aan de regelgeving te voldoen, verzoeken wij u ons te vertellen waar uw account is geregistreerd.',
            skip: 'Overslaan',
            done: 'Klaar',
            next: 'Volgende',
            welcome: 'Welkom op BingeBox!',
            optional: 'Optional',
            allDone:
              'Alle stappen voltooid - bedankt voor uw aanmelding op BingeBox! Klik op BEGIN om te beginnen met bingen!',
            name: 'Naam',
            begin: 'Begin',
            dob: 'Geboortedatum',
            missingFields: 'De volgende velden ontbreken',
            city: 'Stad',
            street: 'Straat',
            country: 'Land',
            postCode: 'Postcode',
            number: 'Nummer',
            cantSkip: 'U kunt geen stap overslaan die niet optioneel is.',
            beforeStart:
              'Voordat u begint, vertel ons iets over uzelf. Laten we beginnen met uw naam en leeftijd.',
          },
          topMenu: {
            movies: 'Films',
            series: 'Series',
            contactUs: 'Klantenservice',
            contactUsTitle: 'Neem contact met ons op!',
            watchlist: 'Kijklijst',
            settings: 'Instellingen',
            logout: 'Log uit',
            thanksForYourFeedback: 'Bedankt voor uw reactie!',
            contactUsDescription:
              'Problemen? Neem contact met ons op! Wij mailen zo snel mogelijk terug.',
          },
          home: {
            mostPopularMovies: 'Meest populaire films',
            mostPopularSeries: 'Meest bingeable series',
          },
          contentDetails: {
            subtitlesIn: 'Ondertitels beschikbaar in',
            startWatching: 'Begin met kijken',
            watchTrailer: 'Bekijk trailer',
            season_one: 'Seizoen',
            season_other: 'Seizoenen',
          },
          userSettings: {
            accountDetails: 'Account instellingen',
            userDetails: 'Persoonlijke informatie',
            securityDetails: 'Beveiliging instellingen',
            subscriptionDetails: 'Abonnement instellingen',
          },
          accountSettings: {
            emailVerified: 'Uw e-mail is geverifieerd.',
            checkYourEmail: 'Controleer uw e-mailadres!',
            accountRegisteredTo:
              'Dit is een persoonlijk account geregistreerd op',
            notYourAccountQuestion: 'Niet uw account?',
            logoutHere: 'Meld u hier af',
            deleteAccount: 'Account opzeggen',
          },
          securitySettings: {
            passwordUpdated: 'Wachtwoord bijgewerkt!',
            changePasswordQuestion:
              'Wilt u het wachtwoord van uw account wijzigen?',
            provideNewPassword:
              'Geef ons uw huidige en nieuwe wachtwoord door.',
            currentPassword: 'Huidige wachtwoord',
            newPassword: 'Nieuwe wachtwoord',
            tooShort: 'te kort',
            tooWeak: 'heel zwak',
            weak: 'zwak',
            okay: 'oké',
            good: 'goed',
            strong: 'sterk',
            updatePassword: 'Wachtwoord veranderen',
          },
          channelSettings: {
            title: 'Channels',
            description: 'Hier vindt u een lijst met al uw gekochte kanalen.',
            explanation:
              'Kanalen zijn een manier voor makers om inhoud samen te bundelen. Zodra je een kanaal koopt, krijg je onbeperkte toegang tot de huidige inhoud en toekomstige releases, zoals nieuwe films, series, seizoenen, afleveringen, en meer.',
          },
          personalSettings: {
            title:
              'Uw persoonlijke gegevens zijn noodzakelijk voor onze abonnement dienst.',
            control: 'Controleer of de onderstaande informatie correct is.',
            profileUpdated: 'Profiel bijgewerkt!',
            privacyInfo:
              'Geen zorgen! We houden uw persoonlijke gegevens altijd privé.',
          },
          subscription: {
            ending: 'einde',
            subscriptionHistory: 'Abonnement geschiedenis',
            status: 'Status',
            paid: 'Betaald',
            yes: 'Ja',
            no: 'Nee',
            upcoming: 'Aankomend',
            amount: 'Aantal',
            paymentMethod: 'Betaal methode',
            start: 'Begin',
            validUntil: 'Geldig t/m',
            upcomingInvoices: 'Aankomende factuur',
            nextPaymentDueOn: 'Volgende betaling op',
            total: 'Totaal',
            automaticPayment: 'Automatische betaling',
            purchase: 'Eenmalige Aankoop',
            month: 'maand',
            year: 'jaar',
            monthlySubscriptionTitle: 'Maandplan',
            monthlySubscriptionDescription:
              'Met het maandabonnement krijgt u onbeperkte toegang tot alle inhoud zonder advertenties.',
            yearlySubscriptionTitle: 'Jaarplan',
            yearlySubscriptionDescription:
              'Met het jaarabonnement steunt u ons platform door 16% te besparen. U profiteert van dezelfde onbeperkte toegang tot alle inhoud, zonder advertenties.',
            cancelSubscription: 'Abonnement opzeggen',
            areYouSureTitle: 'Weet u zeker dat u uw abonnement wilt opzeggen?',
            areYouSureDescription:
              'U kunt uw abonnement op elk moment opzeggen door eenvoudigweg uw verzoek hieronder te bevestigen. Zodra uw abonnement is opgezegd, kunt u nog tot het einde van uw huidige abonnementsperiode van onze content genieten.',
          },
          successPayment: {
            title: 'Bedankt voor uw aankoop!',
            details: 'Uw betaling is succesvol afgerond.',
            redirect: 'U wordt automatisch doorgestuurd naar de hoofdpagina...',
          },
          abortedPayment: {
            title: 'Ops! Iets ging mis...',
            details:
              'Het lijkt erop dat er iets mis is gegaan met uw betaling, maar maak u geen zorgen: er is geen betaling verwerkt.',
            redirect:
              'U wordt automatisch teruggeleid naar uw abonnementsinstellingen...',
          },
          watchContent: {
            purchaseDescription:
              'Door dit kanaal aan te schaffen, krijg je toegang tot alle huidige inhoud en toekomstige releases.',
            onlyAvailableForBuyers: 'Krijg onbeperkt toegang tot dit kanaal.',
            clickToSubscribe: 'Klik hier om te abonneren!',
            sponsorTip:
              'Deze maker profiteert direct van jouw financiering! 💖',
          },
          contentNavigation: {
            noContentFound: 'Excuus, maar er is geen content gevonden...',
            selectGenre: 'Selecteer een genre om te beginnen met kijken...',
          },
          watchlater: {
            title: 'Mijn kijklijst',
            empty: 'Lijst is leeg...',
            addedOn: 'Toegevoegd op',
            removeFromList: 'Verwijder van kijklijst',
            emptyDescription:
              'Hier vindt u de content die u hebt opgeslagen om later te bekijken.',
          },
          notFound: {
            title: 'Niet gevonden',
            details: 'De pagina die u zoekt kan niet worden gevonden.',
            bringeBack: 'Ga terug naar de hoofdpagina...',
          },
        },
      },
      pt: {
        translation: {
          common: {
            sponsor: 'Patrocinar',
            sponsored: 'Patrocinado',
            signIn: 'Entrar',
            viewOn: 'Veja mais no',
            watchNow: 'Assista agora',
            watchLater: 'Curtir',
            duration: 'Duração',
            minutes: 'minutes',
            directedBy: 'Dirigido por',
            castMembers: 'Atores',
            availableIn: 'Disponível em',
            releaseYear: 'Ano de lançamento',
            orOlder: 'e acima',
            watchOn: 'Assista no',
            back: 'Voltar',
            name: 'Nome',
            birthdate: 'Data de nascimento',
            street: 'Rua',
            houseNumber: 'Número',
            city: 'Cidade',
            postcode: 'Código postal',
            country: 'País',
            save: 'Salvar',
            cancel: 'Cancelar',
            confirm: 'Confirmar',
            more: 'Remover',
            close: 'Fechar',
            send: 'Enviar',
            language: 'Idioma',
          },
          genres: {
            action: 'Ação',
            adventure: 'Aventura',
            comedy: 'Comédia',
            crime: 'Crime',
            drama: 'Drama',
            family: 'Familia',
            fantasy: 'Fantasia',
            gameshow: 'Game',
            horror: 'Terror',
            kids: 'Kids',
            reality: 'Reality',
            romance: 'Romance',
            scifi: 'Sci-Fi',
            thriller: 'Suspense',
          },
          landing: {
            requestMoreInformation: 'Solicite mais informações',
            followUs: 'Siga-nos nas redes sociais',
            addYourContent: 'Gostaria de ver o seu conteúdo aqui?',
            originalContent: 'Suporte criadores de séries e filmes locais',
            unlimitedStreaming: 'Streaming ilimitado! Sem mensalidades!',
            startWatching: 'Começe a assistir agora',
            createYourAccount: 'Crie a sua conta!',
            yourDistributor:
              'O seu distribuidor de filmes e séries independentes',
            yourDistributorDescription:
              'A distribuição de conteúdo independente pode ser um desafio desnecessário. BingeBox pode ajudar sendo sua plataforma de distribuição com monetização completamente transparente.',
          },
          login: {
            loginMessage: 'Login na sua conta',
            loginConfirm: 'Confirmar',
            resetPassword: 'Redefinir sua senha',
            resetPasswordConfirm: 'Confirme sua senha',
            createAccount: 'Criar uma nova conta',
            backToSignIn: 'Voltar para Entrar',
            checkYourMail: 'Verifique seu e-mail',
            forms: {
              username: {
                label: 'E-mail',
                placeholder: 'Entre o seu endereço de e-mail',
              },
              password: {
                label: 'Senha',
                placeholder: 'Entre sua senha',
              },
              confirmPassword: {
                label: 'Confirme sua senha',
                placeholder: 'Repita aqui a sua senha',
              },
              confirmationCode: {
                label: 'Código de confirmação',
                placeholder: 'Preencha o código de confirmação aqui',
              },
            },
          },
          wizard: {
            yourProfile: 'Seu perfil',
            yourPersonalData: 'Seus dados pessoais',
            comply:
              'Para seguir regulamentações locais precisamos de algumas informações pessoais.',
            skip: 'Pular',
            done: 'Pronto',
            next: 'Próximo',
            welcome: 'Bem-vindo ao BingeBox!',
            optional: 'Opcional',
            allDone:
              'Todos as etapas foram finalizadas - obrigado por se registrar! Clique em COMEÇAR para navegar em nosso conteúdo!',
            name: 'Nome',
            begin: 'Começar',
            dob: 'Data de nascimento',
            missingFields: 'Os seguintes campos estão vazios',
            city: 'Cidade',
            street: 'Rua',
            country: 'País',
            postCode: 'Código postal',
            number: 'Número',
            cantSkip: 'Você não pode pular uma etapa que não é opcional.',
            beforeStart:
              'Antes de começar, nos informe o seu nome e sua data de nascimento.',
          },
          topMenu: {
            movies: 'Filmes',
            series: 'Séries',
            watchlist: 'Favoritos',
            settings: 'Configurações',
            logout: 'Sair',
            thanksForYourFeedback: 'Obrigado pela sua mensagem!',
            contactUs: 'Suporte',
            contactUsTitle: 'Entre em contato conosco!',
            contactUsDescription:
              'Dificuldades? Entre em contato conosco! Entraremos em contato via e-mail o mais rápido possível.',
          },
          home: {
            mostPopularMovies: 'Filmes mais populares',
            mostPopularSeries: 'Séries mais populares',
          },
          contentDetails: {
            subtitlesIn: 'Legenda disponível em',
            startWatching: 'Assista agora',
            watchTrailer: 'Assistir trailer',
            season_one: 'Temporada',
            season_other: 'Temporadas',
          },
          userSettings: {
            accountDetails: 'Configurações de conta',
            userDetails: 'Dados pessoais',
            securityDetails: 'Configurações de segurança',
            subscriptionDetails: 'Assinatura',
          },
          accountSettings: {
            emailVerified: 'Seu endereço de e-mail foi verificado.',
            checkYourEmail: 'Por favor verifique seu endereço de e-mail!',
            accountRegisteredTo: 'Essa conta está registrada para',
            notYourAccountQuestion: 'Não é a sua conta?',
            logoutHere: 'Faça logout aqui',
            deleteAccount: 'Remover conta',
          },
          securitySettings: {
            passwordUpdated: 'Senha atualizada!',
            changePasswordQuestion: 'Gostaria de mudar sua senha?',
            provideNewPassword:
              'Preencha o formulário abaixo para efetuar a mudança de senha.',
            currentPassword: 'Senha atual',
            newPassword: 'Senha antiga',
            tooShort: 'muito curta',
            tooWeak: 'muito fraca',
            weak: 'fraca',
            okay: 'satisfatório',
            good: 'boa',
            strong: 'forte',
            updatePassword: 'Atualizar senha',
          },
          channelSettings: {
            title: 'Channels',
            description:
              'Abaixo segue a lista de canais disponíveis em sua conta.',
            explanation:
              'Canais são uma forma para os criadores agruparem conteúdo. Uma vez que você compra um canal, você terá acesso ilimitado ao seu conteúdo atual e aos lançamentos futuros, como novos filmes, séries, temporadas, episódios e muito mais.',
          },
          personalSettings: {
            title:
              'Sua informação pessoal é necessário para o nosso serviço de assinatura.',
            control: 'Por favor verifique se a informação abaixo é correta.',
            profileUpdated: 'Perfil atualizado',
            privacyInfo:
              'Não se preocupe! Nós levamos sua privacidade a sério, dados pessoais jamais serão compartilhados.',
          },
          subscription: {
            ending: 'termino',
            subscriptionHistory: 'Histórico de assinaturas',
            status: 'Status',
            paid: 'Pagamento efetuado',
            yes: 'Sim',
            no: 'Não',
            upcoming: 'Lançamento futuro',
            amount: 'Preço',
            paymentMethod: 'Forma de pagamento',
            start: 'Começo',
            validUntil: 'Valido até',
            upcomingInvoices: 'Próxima fatura',
            nextPaymentDueOn: 'Próximo pagamento será lançado em',
            total: 'Total',
            automaticPayment: 'Pagamento automático',
            purchase: 'Pagamento Único',
            month: 'mês',
            year: 'ano',
            monthlySubscriptionTitle: 'Plano mensal',
            monthlySubscriptionDescription:
              'Com o plano mensal você terá acesso a ilimitado a todo conteúdo sem Ads.',
            yearlySubscriptionTitle: 'Plano anual',
            yearlySubscriptionDescription:
              'Com o plano anual você estará suportando a nossa plataform e economizado 16%. Você irá desfrutar do acesso ilimitado a todo conteúdo sem Ads.',
            cancelSubscription: 'Cancelar assinatura',
            areYouSureTitle:
              'Tem certeza que gostaria de cancelar a assinatura?',
            areYouSureDescription:
              'Você pode cancelar sua assinatura a qualquer momento ao confirmar sua solicitação abaixo. Depois que sua assinatura for cancelada, você ainda poderá desfrutar de nosso conteúdo até o final do período de assinatura atual.',
          },
          successPayment: {
            title: 'Obrigado!',
            details: 'Seu pagamento foi efetuado.',
            redirect: 'Você será redirecionado para a página principal...',
          },
          abortedPayment: {
            title: 'Ops! Algo de errado...',
            details:
              'Algo errado ocorreu durante sua tentativa de pagamento, mas não se preocupa, nenhum pagamento foi efetuado.',
            redirect:
              'Você será automaticamente redirecionado para sua página de assinatura...',
          },
          watchContent: {
            purchaseDescription:
              'Ao adquirir esse canal você recebá acesso ao todo seu conteúdo atual, assim como lançamentos futuros.',
            onlyAvailableForBuyers: 'Obtenha accesso ilimitado ao canal.',
            clickToSubscribe: 'Clique aqui para assinar!',
            sponsorTip:
              'Este produtor irá se benefiar diretamente através do seu patrocinio! 💖',
          },
          contentNavigation: {
            noContentFound: 'Nenhum conteúdo encontrado...',
            selectGenre: 'Selecione um gênero para começar...',
          },
          watchlater: {
            title: 'Meus favoritos',
            empty: 'Nenhum conteúdo na lista...',
            addedOn: 'Adicionado em',
            removeFromList: 'Remover dos favoritos',
            emptyDescription: 'Encontre aqui conteúdo favoritado por você.',
          },
          notFound: {
            title: 'Não encontrado',
            details: 'A página que você está procurando não foi encontrada.',
            bringeBack: 'Voltar para página principal...',
          },
        },
      },
      es: {
        translation: {
          common: {
            sponsor: 'Patrocinador',
            sponsored: 'Patrocinado',
            viewOn: 'Ver en',
            signIn: 'Iniciar sesión',
            watchNow: 'Ver ahora',
            watchLater: 'Ver después',
            duration: 'Duración',
            minutes: 'minutos',
            directedBy: 'Dirigida por',
            castMembers: 'Miembros del reparto',
            availableIn: 'Disponible en',
            releaseYear: 'Año de estreno',
            orOlder: 'y más antiguo',
            watchOn: 'Ver en',
            back: 'Volver',
            name: 'Nombre',
            birthdate: 'Fecha de nacimiento',
            street: 'Calle',
            houseNumber: 'Número',
            city: 'Ciudad',
            postcode: 'Código postal',
            country: 'País',
            save: 'Guardar',
            cancel: 'Cancelar',
            confirm: 'Confirmar',
            more: 'Más',
            close: 'Cerrar',
            send: 'Enviar',
            language: 'Idioma',
          },
          genres: {
            action: 'Acción',
            adventure: 'Aventura',
            comedy: 'Comedia',
            crime: 'Crimen',
            drama: 'Drama',
            family: 'Familia',
            fantasy: 'Fantasía',
            gameshow: 'Concurso de juegos',
            horror: 'Terror',
            kids: 'Niños',
            reality: 'Realidad',
            romance: 'Romance',
            scifi: 'Ciencia ficción',
            thriller: 'Thriller',
          },
          landing: {
            requestMoreInformation: 'Solicitar más información',
            followUs: 'Síguenos en redes sociales',
            addYourContent: '¿Te gustaría ver tu contenido aquí?',
            originalContent:
              'Apoya a tus creadores locales de películas y series',
            unlimitedStreaming: 'Streaming ilimitado. Pago único.',
            startWatching: 'Comienza a ver ahora',
            createYourAccount: '¡Crea tu cuenta!',
            yourDistributor:
              'Tu distribuidor de películas y series independientes',
            yourDistributorDescription:
              'Autodistribuir una película puede ser un proceso desafiante. BingeBox puede apoyarte como tu plataforma de distribución con un modelo de monetización completamente transparente.',
          },
          login: {
            loginMessage: 'Inicia sesión en tu cuenta',
            loginConfirm: 'Confirmar',
            resetPassword: 'Restablecer contraseña',
            resetPasswordConfirm: 'Por favor, confirma',
            createAccount: 'Crear cuenta',
            backToSignIn: 'Volver a iniciar sesión',
            checkYourMail: 'Revisa tu correo electrónico',
            forms: {
              username: {
                label: 'Correo electrónico',
                placeholder: 'Ingresa tu dirección de correo electrónico',
              },
              password: {
                label: 'Contraseña',
                placeholder: 'Ingresa tu contraseña',
              },
              confirmPassword: {
                label: 'Confirmar tu contraseña',
                placeholder: 'Repite tu contraseña',
              },
              confirmationCode: {
                label: 'Código de confirmación',
                placeholder: 'Ingresa tu código de confirmación',
              },
            },
          },
          wizard: {
            yourProfile: 'Tu perfil',
            yourPersonalData: 'Tus datos personales',
            comply:
              'Para cumplir con las regulaciones locales, por favor, indícanos dónde estás registrando tu cuenta a continuación.',
            skip: 'Omitir',
            done: 'Listo',
            next: 'Siguiente',
            welcome: '¡Bienvenido a BingeBox!',
            optional: 'Opcional',
            allDone:
              'Todos los pasos completados, ¡gracias por unirte a BingeBox! Haz clic en COMENZAR para empezar a ver contenido.',
            name: 'Nombre',
            begin: 'Comenzar',
            dob: 'Fecha de nacimiento',
            missingFields: 'Faltan los siguientes campos',
            city: 'Ciudad',
            street: 'Calle',
            country: 'País',
            postCode: 'Código postal',
            number: 'Número',
            cantSkip: 'No puedes omitir un paso que no es opcional.',
            beforeStart:
              'Antes de empezar, cuéntanos sobre ti. Comencemos con tu nombre y edad.',
          },
          topMenu: {
            movies: 'Películas',
            series: 'Series',
            watchlist: 'Lista de seguimiento',
            settings: 'Configuración',
            logout: 'Cerrar sesión',
            thanksForYourFeedback: '¡Gracias por tu retroalimentación!',
            contactUs: 'Soporte',
            contactUsTitle: 'Comunícate con nosotros',
            contactUsDescription:
              '¿Tienes problemas? ¡Comunícate con nosotros! Haremos nuestro mejor esfuerzo para responderte lo antes posible.',
          },
          home: {
            mostPopularMovies: 'Películas más populares',
            mostPopularSeries: 'Series más populares',
          },
          contentDetails: {
            subtitlesIn: 'Subtítulos disponibles en',
            startWatching: 'Comenzar a ver',
            watchTrailer: 'Ver tráiler',
            season_one: 'Temporada',
            season_other: 'Temporadas',
          },
          userSettings: {
            accountDetails: 'Configuración de cuenta',
            userDetails: 'Información personal',
            securityDetails: 'Configuración de seguridad',
            subscriptionDetails: 'Suscripción',
          },
          accountSettings: {
            emailVerified:
              'Tu dirección de correo electrónico ha sido verificada.',
            checkYourEmail:
              '¡Por favor, verifica tu dirección de correo electrónico!',
            accountRegisteredTo: 'Esta cuenta está registrada a',
            notYourAccountQuestion: '¿No es tu cuenta?',
            logoutHere: 'Cerrar sesión aquí',
            deleteAccount: 'Eliminar cuenta',
          },
          securitySettings: {
            passwordUpdated: '¡Contraseña actualizada!',
            changePasswordQuestion: '¿Quieres cambiar tu contraseña?',
            provideNewPassword:
              'Para hacerlo, proporciona tu contraseña actual y una nueva contraseña.',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            tooShort: 'demasiado corta',
            tooWeak: 'demasiado débil',
            weak: 'débil',
            okay: 'bien',
            good: 'bueno',
            strong: 'fuerte',
            updatePassword: 'Actualizar contraseña',
          },
          channelSettings: {
            title: 'Canales',
            description:
              'A continuación, puedes encontrar una lista con todos los canales que has comprado:',
            explanation:
              'Los canales son una forma para que los creadores agrupen contenido. Una vez que compras un canal, tendrás acceso ilimitado a su contenido actual y a futuros lanzamientos, como nuevas películas, series, temporadas, episodios y más.',
          },
          personalSettings: {
            title:
              'Tus detalles personales son necesarios para nuestro servicio de pago.',
            control:
              'Por favor, verifica si toda la información a continuación es correcta.',
            profileUpdated: 'Perfil actualizado',
            privacyInfo:
              '¡No te preocupes! Tomamos tu privacidad en serio y tu información personal nunca se compartirá con terceros.',
          },
          subscription: {
            ending: 'finalizando',
            subscriptionHistory: 'Historial de suscripción',
            status: 'Estado',
            paid: 'Pagado',
            yes: 'Sí',
            no: 'No',
            upcoming: 'Próximos',
            amount: 'Cantidad',
            paymentMethod: 'Método de pago',
            start: 'Comienzo',
            validUntil: 'Válido hasta',
            upcomingInvoices: 'Próximas facturas',
            nextPaymentDueOn: 'Próximo pago programado para',
            total: 'Total',
            automaticPayment: 'Pago automático',
            purchase: 'Compra única',
            month: 'mes',
            year: 'año',
            monthlySubscriptionTitle: 'Pase mensual',
            monthlySubscriptionDescription:
              'Con el pase mensual, tienes acceso ilimitado a todo el contenido sin anuncios.',
            yearlySubscriptionTitle: 'Pase anual',
            yearlySubscriptionDescription:
              'Con el pase anual, apoyas nuestra plataforma ahorrando un 16%. Disfrutarás del mismo acceso ilimitado a todo el contenido sin anuncios.',
            cancelSubscription: 'Cancelar suscripción',
            areYouSureTitle:
              '¿Estás seguro de que quieres cancelar tu suscripción?',
            areYouSureDescription:
              'Eres libre de cancelar tu suscripción en cualquier momento simplemente confirmando tu solicitud a continuación. Una vez cancelada, aún podrás disfrutar de nuestro contenido hasta el final de tu período de suscripción actual.',
          },
          successPayment: {
            title: '¡Gracias por tu compra!',
            details: 'Tu pedido se completó con éxito.',
            redirect:
              'Serás redirigido automáticamente a la página principal...',
          },
          abortedPayment: {
            title: '¡Ops! Algo salió mal...',
            details:
              'Parece que algo salió mal con tu pedido, pero no te preocupes, no se ha procesado ningún pago.',
            redirect:
              'Serás redirigido automáticamente a la configuración de tu suscripción...',
          },
          watchContent: {
            purchaseDescription:
              'Al comprar este canal, tendrás acceso a todo su contenido actual y a futuros lanzamientos.',
            onlyAvailableForBuyers: 'Obtén acceso ilimitado a este canal.',
            clickToSubscribe: '¡Haz clic aquí para suscribirte!',
            sponsorTip:
              '¡Este creador se beneficiará directamente de tu financiamiento! 💖',
          },
          contentNavigation: {
            noContentFound:
              'Disculpas, pero no se encontró ningún contenido...',
            selectGenre: 'Selecciona un género para comenzar...',
          },
          watchlater: {
            title: 'Mi lista de seguimiento',
            empty: 'La lista está vacía...',
            addedOn: 'Añadido el',
            removeFromList: 'Eliminar de la lista de seguimiento',
            emptyDescription:
              'Aquí puedes encontrar el contenido que marcaste para ver más tarde.',
          },
          notFound: {
            title: 'No encontrado',
            details: 'No se pudo encontrar la página que estás buscando.',
            bringeBack: 'Llévame de vuelta a casa...',
          },
        },
      },
    },
  });

export default i18n;

import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
  Alert,
  AlertTitle,
  Box,
  DialogContent,
  Rating,
  Snackbar,
  Typography,
} from '@mui/material';
import {
  useFetchUserContentRatingQuery,
  useRateContentMutation,
} from '../../store';
import { AuthContext } from '../../contexts';

export interface RatingDialogProps {
  contentType: string;
  contentId: string;
  open: boolean;
  onClose: () => void;
}

export default function RatingDialog(props: RatingDialogProps) {
  const { onClose, open } = props;
  const context = React.useContext(AuthContext);

  const userContentRating = useFetchUserContentRatingQuery({
    contentType: props.contentType,
    contentId: props.contentId,
    token: context.token,
  });

  const handleClose = () => {
    onClose();
  };

  const [rateContent, rateContentResults] = useRateContentMutation();

  const handleRating = (rating: number) => {
    rateContent({
      contentType: props.contentType,
      contentId: props.contentId,
      rating: rating,
      token: context.token,
    });
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={'xs'} fullWidth>
      <DialogTitle align="center" sx={{ mt: 2 }}>
        Rate this content
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mx: 'auto',
            mb: 2,
            width: 'fit-content',
          }}
        >
          <Rating
            name="rating"
            precision={0.5}
            value={userContentRating.data ? userContentRating.data.rating : 0}
            size="large"
            onChange={(_, rating) => {
              handleRating(rating!);
            }}
          />
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            Your rating:{' '}
            {userContentRating.data ? userContentRating.data.rating : 0}
          </Typography>
        </Box>
      </DialogContent>
      <Snackbar
        open={rateContentResults.isError}
        autoHideDuration={1000}
        message="You can only rate contents you purchased."
      >
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          You can only rate contents you purchased.
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

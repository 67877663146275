import {
  Box,
  Button,
  Dialog,
  Grid,
  Paper,
  PaperProps,
  Typography,
  styled,
} from '@mui/material';
import { purple } from '@mui/material/colors';
import React from 'react';
import { SeasonsBox } from './SeasonsBox';
import './SeasonsModalButton.css';
import { useTranslation } from 'react-i18next';

const CustomPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[900]),
  backgroundColor: '#570A57',
  '&:hover': {
    backgroundColor: '#570A57',
  },
}));

interface SeasonsModalButtonProps {
  seriesId: string;
}

function SeasonsModalButton(props: SeasonsModalButtonProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{ ml: 0, mb: 1, mr: 1 }}
      >
        {t('contentDetails.season', { count: 2 })}
      </Button>
      <Dialog
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
        PaperComponent={CustomPaper}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="modal">
          <Grid
            container
            alignContent={'left'}
            justifyContent={'left'}
            direction={'column'}
          >
            <Grid item container xs>
              <Grid item xs={6}>
                <Typography variant="h4" sx={{ pl: 3, mt: 3 }}>
                  {t('contentDetails.season', { count: 2 })}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ float: 'right', pr: 5, mt: 3 }}>
                  <Button variant="contained" onClick={handleClose}>
                    {t('common.close')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs sx={{ mb: 10 }}>
              <SeasonsBox serieId={props.seriesId} />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default SeasonsModalButton;

import {
  useTheme,
  View,
  Image,
  Heading,
  useAuthenticator,
  Button,
  Authenticator,
} from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';

I18n.putVocabularies(translations);
I18n.setLanguage(i18n.language);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="BingeBox Logo"
          src="/logo.png"
          style={{ width: 220, marginTop: '8%' }}
        />
      </View>
    );
  },

  SignIn: {
    Header() {
      const { t } = useTranslation();
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={6}
        >
          {t('login.loginMessage')}
        </Heading>
      );
    },
    Footer() {
      const { t } = useTranslation();
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            style={{ marginBottom: 20 }}
          >
            {t('login.resetPassword')}
          </Button>
        </View>
      );
    },
  },
  SignUp: {
    Header() {
      const { t } = useTranslation();
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={6}
        >
          {t('login.createAccount')}
        </Heading>
      );
    },
    Footer() {
      const { t } = useTranslation();
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
            style={{ marginBottom: 20 }}
          >
            {t('login.backToSignIn')}
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { t } = useTranslation();
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={6}
        >
          {t('login.checkYourMail')}
        </Heading>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      const { t } = useTranslation();
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={6}
        >
          {t('login.loginConfirm')}
        </Heading>
      );
    },
  },
  ResetPassword: {
    Header() {
      const { t } = useTranslation();
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={6}
        >
          {t('login.resetPassword')}
        </Heading>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { t } = useTranslation();
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={6}
        >
          {t('login.resetPasswordConfirm')}
        </Heading>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      label: i18n.t('login.forms.username.label'),
      placeholder: i18n.t('login.forms.username.placeholder'),
    },
    password: {
      label: i18n.t('login.forms.password.label'),
      placeholder: i18n.t('login.forms.password.placeholder'),
    },
  },
  signUp: {
    username: {
      label: i18n.t('login.forms.username.label'),
      placeholder: i18n.t('login.forms.username.placeholder'),
    },
    password: {
      label: i18n.t('login.forms.password.label'),
      placeholder: i18n.t('login.forms.password.placeholder'),
    },
    confirm_password: {
      label: i18n.t('login.forms.confirmPassword.label'),
      placeholder: i18n.t('login.forms.confirmPassword.placeholder'),
    },
  },
  forceNewPassword: {
    password: {
      label: i18n.t('login.forms.password.label'),
      placeholder: i18n.t('login.forms.password.placeholder'),
    },
  },
  resetPassword: {
    username: {
      label: i18n.t('login.forms.username.label'),
      placeholder: i18n.t('login.forms.username.placeholder'),
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      label: i18n.t('login.forms.confirmationCode.label'),
      placeholder: i18n.t('login.forms.confirmationCode.placeholder'),
    },
    password: {
      label: i18n.t('login.forms.password.label'),
      placeholder: i18n.t('login.forms.password.placeholder'),
    },
    confirm_password: {
      label: i18n.t('login.forms.confirmPassword.label'),
      placeholder: i18n.t('login.forms.confirmPassword.placeholder'),
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: i18n.t('login.forms.confirmationCode.label'),
      placeholder: i18n.t('login.forms.confirmationCode.placeholder'),
    },
  },
};

type CognitoIdentityBoxProps = {
  children: any;
  initialState: 'signIn' | 'resetPassword' | 'signUp' | undefined;
};

export default function CognitoIdentityBox(props: CognitoIdentityBoxProps) {
  return (
    <Authenticator
      formFields={formFields}
      components={components}
      variation="default"
      i18nIsDynamicList={true}
      initialState={props.initialState}
    >
      {props.children}
    </Authenticator>
  );
}

import * as React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { AuthContext } from '../../../../contexts';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import MoreButton from './components/MoreButton';
import { useTranslation } from 'react-i18next';

function AccountSettings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authContext = React.useContext(AuthContext);

  // To prevent undesired re-renders, you can pass a function to useAuthenticator
  // that takes in Authenticator context and returns an array of desired context values.
  // The hook will only trigger re-render if any of the array values change.
  const { signOut } = useAuthenticator((context) => [context.user]);

  const handleSignOut = () => {
    signOut();
    navigate('/');
  };

  const verifyEmailAlert = () => {
    if (authContext.emailVerified) {
      return t('accountSettings.emailVerified');
    } else {
      return t('accountSettings.checkYourEmail');
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2">
          {t('accountSettings.accountRegisteredTo')}{' '}
          <span style={{ color: '#1976d2' }}>{authContext.email}</span>.
        </Typography>
        <Typography variant="body2" mt={1}>
          {verifyEmailAlert()}
        </Typography>
      </Grid>
      <Grid container item xs={12} mt={3} mb={2}>
        <Grid item xs={9}>
          <Typography
            pb={1.2}
            sx={{ fontSize: { xs: 12, sm: 14 } }}
            variant={'body2'}
            mt={5}
          >
            {t('accountSettings.notYourAccountQuestion')}{' '}
            <Link
              onClick={handleSignOut}
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              {' '}
              {t('accountSettings.logoutHere')}
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent={'flex-end'}
          alignItems={'flex-end'}
          xs={3}
        >
          <MoreButton />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AccountSettings;

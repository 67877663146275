import * as React from 'react';
import {
  movieServiceUrl,
  serieServiceUrl,
  authorizerServiceUrl,
} from '../../urls';
import { AuthContext } from '../../contexts';
import { PurchaseChannel } from './PurchaseChannel';
import { Content } from '../../types/Content';

interface CustomPlayerProps {
  contentType: string;
  contentId: string;
}

function CustomPlayer(props: CustomPlayerProps) {
  const authContext = React.useContext(AuthContext);
  const [renderSubscribe, setRenderSubscribe] = React.useState<boolean>(false);
  const [contentChannelId, setContentChannelId] = React.useState<string>();
  const [token, setToken] = React.useState({
    hash: '',
    expirationTime: '',
    videoId: '',
    libraryId: '',
  });

  React.useEffect(() => {
    const fetchWatchToken = async () => {
      const response = await fetch(`${authorizerServiceUrl}/watch`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authContext.token}`,
        },
        body: JSON.stringify({
          contentType: props.contentType,
          contentId: props.contentId,
        }),
      });

      if (response.status === 403) {
        setRenderSubscribe(true);
      }

      const resp = await response.json();
      setToken(resp);
    };

    const fetchContentChannelId = async () => {
      var contentUrl = '';

      if (
        props.contentType === 'movie' ||
        props.contentType === 'moviesTrailer'
      ) {
        contentUrl = movieServiceUrl + '/' + props.contentId;
      }

      if (
        props.contentType === 'series' ||
        props.contentType === 'seriesTrailer'
      ) {
        contentUrl = serieServiceUrl + '?episodeId=' + props.contentId;
      }

      const response = await fetch(contentUrl, {
        mode: 'cors',
        headers: { Authorization: `Bearer ${authContext.token}` },
      });
      const content: Content = await response.json();
      setContentChannelId(content.channelId);
    };

    fetchWatchToken();
    fetchContentChannelId();
  }, [props, authContext]);

  if (renderSubscribe) {
    return <PurchaseChannel channelId={contentChannelId!} />;
  }

  return (
    <React.Fragment>
      <div
        className="video-container"
        style={{
          position: 'relative',
          overflow: 'hidden',
          paddingTop: '56.25%',
        }}
      >
        <iframe
          key={token.videoId}
          title="video"
          src={`https://iframe.mediadelivery.net/embed/${token.libraryId}/${token.videoId}?token=${token.hash}&expires=${token.expirationTime}&autoplay=true`}
          loading="lazy"
          style={{
            border: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen={true}
        />
      </div>
    </React.Fragment>
  );
}

export default CustomPlayer;

import ReactDOM from 'react-dom/client';
// Pages:
import reportWebVitals from './reportWebVitals';
// Theme:
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import './index.css';
// AWS Cognito:
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// Redux Store:
import { Provider } from 'react-redux';
import { store } from './store';

import './i18n';
import App from './App';

const stripePromise = loadStripe(
  'pk_test_51O33NaIqFDrt5wqo8FysyQnl224XlHcZfgwOWCoFTQGCjy8zk1fP3LvH3DupUZyLthZaSkkH9YihMLkHJcDhGI7j00GyV0Colt',
);

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Authenticator.Provider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <App />
        </Provider>
      </Elements>
    </ThemeProvider>
  </Authenticator.Provider>,
);

reportWebVitals();

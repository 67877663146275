import { createContext } from 'react';

export type AuthContextType = {
  token: string | null;
  email: string | undefined | null;
  emailVerified: boolean | undefined;
};

export const AuthContext = createContext<AuthContextType>({
  token: null,
  email: null,
  emailVerified: false,
});

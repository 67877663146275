import React from 'react';
import { Navigate } from 'react-router-dom';
import CognitoIdentityBox from './landing/components/CognitoIdentityBox';

function SignInPage() {
  return (
    <CognitoIdentityBox initialState="signIn">
      <Navigate to="/home" />
    </CognitoIdentityBox>
  );
}

export default SignInPage;

import React, { useContext, useEffect, useState } from 'react';
import ContentNavigationBox from './components/ContentNavigationBox';
import { useParams } from 'react-router-dom';
import { AuthContext, AuthContextType } from '../../../../contexts';
import {
  genreServiceUrl,
  movieServiceUrl,
  serieServiceUrl,
} from '../../../../urls';
import { Box, CircularProgress } from '@mui/material';

interface GenreContentNavigationProps {
  contentType: string;
}

function GenreContentNavigation(props: GenreContentNavigationProps) {
  let { genreId } = useParams();
  genreId = genreId ? genreId : '';
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [content, setContent] = useState([]);
  const [genre, setGenre] = useState([]);

  useEffect(() => {
    getGenreById(genreId, authContext);

    if (props.contentType === 'movie') {
      getMoviesByGenre(genreId, authContext);
    }

    if (props.contentType === 'series') {
      getSeriesByGenre(genreId, authContext);
    }
  }, [props, genreId, authContext]);

  const getGenreById = async (
    genreId: string | undefined,
    authContext: AuthContextType,
  ) => {
    setIsLoading(true);
    const response = await fetch(genreServiceUrl + '/' + genreId, {
      mode: 'cors',
      headers: { Authorization: `Bearer ${authContext.token}` },
    });
    const jsonData = await response.json();
    setGenre(jsonData);
    setIsLoading(false);
  };

  const getMoviesByGenre = async (
    genreId: string | undefined,
    authContext: AuthContextType,
  ) => {
    setIsLoading(true);
    const response = await fetch(movieServiceUrl + '?genreId=' + genreId, {
      mode: 'cors',
      headers: { Authorization: `Bearer ${authContext.token}` },
    });
    const jsonData = await response.json();
    setContent(jsonData);
    setIsLoading(false);
  };

  const getSeriesByGenre = async (
    genreId: string | undefined,
    authContext: AuthContextType,
  ) => {
    setIsLoading(true);
    const response = await fetch(serieServiceUrl + '?genreId=' + genreId, {
      mode: 'cors',
      headers: { Authorization: `Bearer ${authContext.token}` },
    });
    const jsonData = await response.json();
    setContent(jsonData);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Box>
        <CircularProgress
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    );
  }

  return (
    <ContentNavigationBox
      contentType={props.contentType}
      genre={genre}
      contents={content}
    />
  );
}

export default GenreContentNavigation;

import React from 'react';
import { Grid, Button, GridProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface GenreButtonProps extends GridProps {
  title: string;
  contentType: string;
  genreId: string;
}

export function GenreButton(props: GenreButtonProps) {
  const { sx, contentType, genreId, title, ...other } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/' + contentType + '/genres/' + genreId);
  };

  return (
    <Grid
      item
      sx={{
        flexGrow: 0.1,
        m: 0.6,
        ...sx,
      }}
      {...other}
    >
      <Button
        onClick={handleClick}
        sx={{
          p: 1,
          width: '100%',
          height: '100%',
          color: 'white',
        }}
      >
        {title}
      </Button>
    </Grid>
  );
}

import {
  Box,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

interface ContentCardProps {
  contentType: string;
  content: Content;
}

interface Content {
  _id: string;
  title: string;
  year: number;
  durationMinutes: number;
  thumbnail: {
    source: string;
  };
}

export function ContentCard(props: ContentCardProps) {
  /**
   * Posters come from a central location, that is BunnyCDN, a timestamp is added
   * to assist with caching, given that this image is prone to change.
   */
  const getPosterUrl = (contentId?: string) => {
    const currentTime = `${Date.now()}`;
    const portersStore = 'https://bingebox-public.b-cdn.net/posters';
    if (!contentId) {
      return `${portersStore}/generic-poster.png?${currentTime}`;
    }
    return `${portersStore}/${contentId}?${currentTime}`;
  };

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = getPosterUrl();
  };

  return (
    <Card
      sx={{
        width: 190,
        height: 320,
        m: 0.3,
        backgroundColor: 'transparent',
        border: 0,
        boxShadow: 0,
      }}
    >
      <CardContent>
        <Link to={'/details/' + props.contentType + '/' + props.content._id}>
          <ButtonBase>
            <Box>
              <img
                alt={props.content.title}
                width={'160px'}
                height={'237px'}
                src={getPosterUrl(props.content._id)}
                onError={handleImageError}
              />
            </Box>
          </ButtonBase>
        </Link>
        <Typography
          variant="subtitle2"
          color="whitesmoke"
          fontFamily={'Lexend'}
          sx={{ mt: 0.4 }}
        >
          {props.content.title} ({props.content.year})
        </Typography>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

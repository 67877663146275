import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserSettings from './settings/UserSettings';
import SecuritySettings from './settings/SecuritySettings';
import AccountSettings from './settings/AccountSettings';
import { Badge, Person, Security, Tv } from '@mui/icons-material';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChannelSettings from './settings/ChannelSettings';

function ProfileAccordion() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const lastHash = useRef('');
  const location = useLocation();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current === 'channels') {
      setTimeout(() => {
        setExpanded('panel3');
      }, 500);
    }
  }, [location, setExpanded]);

  return (
    <>
      <Accordion
        expanded={expanded === 'panel0'}
        onChange={handleChange('panel0')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Stack direction="row" spacing={1}>
            <Badge />
            <Typography sx={{ flexShrink: 0 }}>
              {t('userSettings.accountDetails')}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ px: { xs: 4, sm: 3 } }}>
          <AccountSettings />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Stack direction="row" spacing={1}>
            <Person />
            <Typography sx={{ flexShrink: 0 }}>
              {t('userSettings.userDetails')}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ px: { xs: 4, sm: 3 } }}>
          <UserSettings />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Stack direction="row" spacing={1}>
            <Security />
            <Typography sx={{ flexShrink: 0 }}>
              {t('userSettings.securityDetails')}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ px: { xs: 4, sm: 3 } }}>
          <SecuritySettings />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Stack direction="row" spacing={1}>
            <Tv />
            <Typography sx={{ flexShrink: 0 }}>
              {t('channelSettings.title')}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ px: { xs: 4, sm: 3 } }}>
          <ChannelSettings />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default ProfileAccordion;

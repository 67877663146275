export const movieServiceUrl = 'https://api.bingebox.nl/movies';
export const serieServiceUrl = 'https://api.bingebox.nl/series';
export const genreServiceUrl = 'https://api.bingebox.nl/genres';
export const userServiceUrl = 'https://api.bingebox.nl/users';
export const authorizerServiceUrl = 'https://api.bingebox.nl/authorizer';
export const feedbacksServiceUrl = 'https://api.bingebox.nl/feedbacks';
export const watchlaterServiceUrl = 'https://api.bingebox.nl/watchlater';
export const paymentsServiceUrl = 'https://api.bingebox.nl/payments';
export const channelsServiceUrl = 'https://api.bingebox.nl/channels';
export const ratingsServiceUrl = 'https://api.bingebox.nl/ratings';

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function CancelledPayment() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/home/profile#subscription');
    }, 6000);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 10,
      }}
    >
      <ErrorOutline sx={{ fontSize: 100, color: '#cba950' }} />
      <Typography variant="h4" color="white">
        {t('abortedPayment.title')}
      </Typography>
      <Typography color="white">{t('abortedPayment.details')}</Typography>
      <Typography color="white" variant="caption" sx={{ mt: 2 }}>
        {t('abortedPayment.redirect')}
      </Typography>
    </Box>
  );
}
export default CancelledPayment;

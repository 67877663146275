import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
  Card,
  CardProps,
  styled,
} from '@mui/material';
import * as React from 'react';
import {
  movieServiceUrl,
  serieServiceUrl,
  watchlaterServiceUrl,
} from '../../../urls';
import { AuthContext } from '../../../contexts';
import { purple } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { WatchLaterType } from '../../../types/WatchLater';
import { Content } from '../../../types/Content';

function addLeadingZeros(number: number, length: number): string {
  return number.toString().padStart(length, '0');
}

function parseDate(ts: any, displayTime: boolean = true): string {
  const date = new Date(ts);
  const [month, day, year] = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
  ];

  const [hour, minutes] = [date.getHours(), date.getMinutes()];

  const dayStr = addLeadingZeros(day, 2);
  const monthStr = addLeadingZeros(month, 2);
  const yearStr = addLeadingZeros(year, 2);
  const hourStr = addLeadingZeros(hour, 2);
  const minuteStr = addLeadingZeros(minutes, 2);

  const dateString = `${dayStr}/${monthStr}/${yearStr}`;
  const timeString = `${hourStr}:${minuteStr}`;

  return `${dateString} ${displayTime ? `- ${timeString}` : ''}`;
}

interface WatchListItemProps {
  watchlater: WatchLaterType;
}

function WatchListItem(props: WatchListItemProps) {
  const { t } = useTranslation();
  const authContext = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [content, setContent] = React.useState<Content>();

  React.useEffect(() => {
    const fetchContentMetadata = async (contentType: string) => {
      if (contentType === 'series') {
        const response = await fetch(
          serieServiceUrl + '/' + props.watchlater.contentId,
          {
            mode: 'cors',
            headers: { Authorization: `Bearer ${authContext.token}` },
          },
        );
        if (response.ok) {
          const jsonData: Content = await response.json();
          setContent(jsonData);
        }
      }
      if (contentType === 'movie') {
        const response = await fetch(
          movieServiceUrl + '/' + props.watchlater.contentId,
          {
            mode: 'cors',
            headers: { Authorization: `Bearer ${authContext.token}` },
          },
        );
        if (response.ok) {
          const jsonData: Content = await response.json();
          setContent(jsonData);
        }
      }
      setIsLoading(false);
    };

    fetchContentMetadata(props.watchlater.contentType);
  }, [props, authContext]);

  const handleRemove = async () => {
    try {
      await fetch(watchlaterServiceUrl + '/' + props.watchlater.contentId, {
        mode: 'cors',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authContext.token}`,
        },
      });
    } catch (e) {
      console.log(e);
    }
    window.location.reload();
  };

  const renderDate = () => {
    return <span>{parseDate(props.watchlater.createdAt)}</span>;
  };

  if (isLoading) {
    return (
      <Grid container maxWidth={'md'} sx={{ mt: 1, mb: 1 }}>
        <Grid item xs={12}>
          <center>
            <CircularProgress />
          </center>
        </Grid>
      </Grid>
    );
  }

  const CustomCard = styled(Card)<CardProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[900]),
    backgroundColor: '#5d4152',
    '&:hover': {
      backgroundColor: '#5e4152',
    },
  }));

  /**
   * Posters come from a central location, that is BunnyCDN, a timestamp is added
   * to assist with caching, given that this image is prone to change.
   */
  const getPosterUrl = (contentId?: string) => {
    const currentTime = `${Date.now()}`;
    const portersStore = 'https://bingebox-public.b-cdn.net/posters';
    if (!contentId) {
      return `${portersStore}/generic-poster.png?${currentTime}`;
    }
    return `${portersStore}/${contentId}?${currentTime}`;
  };

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = getPosterUrl();
  };

  return (
    <Grid
      container
      maxWidth={'md'}
      sx={{ mt: 1, pt: 2, mb: 1, pb: 1 }}
      component={CustomCard}
    >
      <Grid item xs={4} sm={2}>
        <center>
          <img
            alt="poster"
            width={`110px`}
            height={`172px`}
            src={getPosterUrl(content?._id)}
            onError={handleImageError}
          />
        </center>
      </Grid>
      <Grid
        item
        container
        xs={8}
        sm={10}
        display={'flex'}
        alignContent={'flex-start'}
      >
        <Grid item xs={12}>
          <Typography color="whitesmoke" variant="h5">
            {content?.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: -0.5, mb: 1 }}>
          <Typography variant="caption" color="#d1d1d1">
            {t('watchlater.addedOn')} {renderDate()}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ pr: 8, mb: 1 }}>
          <Typography
            variant="caption"
            color="#d1d1d1"
            sx={{
              display: '-webkit-box',
              whiteSpace: 'wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: 58,
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {content?.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color={'gray'}>
            <Link
              underline="none"
              color="inherit"
              fontSize={12}
              href="#"
              onClick={handleRemove}
            >
              🗑️ {t('watchlater.removeFromList')}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }} alignSelf={'flex-end'}>
          <Button
            sx={{ float: 'right', mr: 2, fontSize: 10 }}
            variant="contained"
            size="small"
            href={
              '/details/' +
              props.watchlater.contentType +
              '/' +
              props.watchlater.contentId
            }
          >
            {t('common.watchNow')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default WatchListItem;

import React, { useContext } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
// import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { LeftArrow, RightArrow } from './components/Arrows';
import { ContentCard } from './components/ContentCard';
import { Box, CircularProgress, Typography } from '@mui/material';
import './HorizontalNavigation.css';
import { AuthContext, AuthContextType } from '../../../contexts';
import { movieServiceUrl, serieServiceUrl } from '../../../urls';

// type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface Content {
  _id: string;
  title: string;
  durationMinutes: number;
  year: number;
  thumbnail: {
    source: string;
  };
}

interface HorizontalNavigationProps {
  header: string;
  contentType: string;
}

function HorizontalNavigation(props: HorizontalNavigationProps) {
  const [contents, setContents] = React.useState<Content[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const authContext = useContext(AuthContext);

  React.useEffect(() => {
    getContents(props.contentType, authContext);
  }, [props, authContext]);

  const getContents = async (
    contentType: string,
    authContext: AuthContextType,
  ) => {
    setIsLoading(true);
    if (contentType === 'series') {
      const response = await fetch(serieServiceUrl, {
        mode: 'cors',
        headers: { Authorization: `Bearer ${authContext.token}` },
      });
      const jsonData: Content[] = await response.json();
      setContents(jsonData);
    }
    if (contentType === 'movie') {
      const response = await fetch(movieServiceUrl, {
        mode: 'cors',
        headers: { Authorization: `Bearer ${authContext.token}` },
      });
      const jsonData: Content[] = await response.json();
      setContents(jsonData);
    }
    setIsLoading(false);
  };

  const renderContents = (contents: Content[]) => {
    return contents.map((content) => (
      <ContentCard
        key={content._id}
        content={content}
        contentType={props.contentType}
      />
    ));
  };

  if (isLoading) {
    return (
      <Box className="horizontalNavigation">
        <CircularProgress sx={{ ml: '50%' }} />
      </Box>
    );
  }

  return (
    <Box className="horizontalNavigation">
      <ScrollMenu
        Header={
          <Box sx={{ mb: 1, ml: 6, mt: 1 }}>
            <Typography variant="h5" sx={{ color: 'white' }}>
              {props.header}
            </Typography>
          </Box>
        }
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        // onWheel={onWheel}
        transitionDuration={1400}
      >
        {renderContents(contents ? contents : [])}
      </ScrollMenu>
    </Box>
  );
}
export default HorizontalNavigation;

// function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
//   const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

//   if (isThouchpad) {
//     ev.stopPropagation();
//     return;
//   }

//   if (ev.deltaY < 0) {
//     apiObj.scrollNext();
//   } else if (ev.deltaY > 0) {
//     apiObj.scrollPrev();
//   }
// }

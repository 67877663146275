import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  ButtonProps,
  Container,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import UserIconWithMenu from './components/UserIconWithMenu';
import BurgerMenu from './components/BurgerMenu';

interface TopMenuBarProps {
  isLoading: boolean;
}

function TopMenu(props: TopMenuBarProps) {
  const { t } = useTranslation();
  const pages = [
    { title: t('topMenu.movies'), href: '/movie/genres' },
    { title: t('topMenu.series'), href: '/series/genres' },
  ];

  const CustomRippleButtom = styled(Button)<ButtonProps>(({ theme }) => ({
    '& .MuiTouchRipple-child': {
      backgroundColor: '#ab4772 !important',
    },
  }));

  return (
    <AppBar position="static" sx={{ background: '#9a76a3', boxShadow: 0 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Left logo uncollapsed menu */}
          <Typography
            variant="h6"
            noWrap
            component="span"
            sx={{
              mr: 2,
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          >
            <Link to="/home">
              <img
                className="fixed-image"
                src="/logo.png"
                alt="logo"
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  height: 50,
                  width: 190,
                }}
              />
            </Link>
          </Typography>

          {/* Left collapsed menu */}
          <BurgerMenu />

          {/* Buttons to pages */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link
                key={page.title}
                to={page.href}
                style={{ textDecoration: 'none' }}
              >
                <CustomRippleButtom
                  key={page.title}
                  sx={{
                    px: 2,
                    my: 2,
                    color: 'white',
                    display: 'block',
                    mr: 2,
                    textAlign: 'center',
                  }}
                >
                  {page.title}
                </CustomRippleButtom>
              </Link>
            ))}
          </Box>

          {/* Logo collapsed bar */}
          <Typography
            variant="h5"
            component="span"
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
              },
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Link to={'/home'}>
              <img
                src="/logo.png"
                alt="logo"
                style={{
                  marginTop: 10,
                  marginBottom: 0,
                  height: 40,
                  width: 160,
                }}
              />
            </Link>
          </Typography>

          {/* User icon on right */}
          <UserIconWithMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopMenu;

import React, { useContext, useEffect } from 'react';
import { Button } from '@mui/material';
import { AuthContext, AuthContextType } from '../../../contexts';
import { watchlaterServiceUrl } from '../../../urls';
import { Bookmark, HourglassBottom } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface WatchLaterButtonProps {
  contentType: string;
  contentId: string | null | undefined;
}

export function WatchLaterButton(props: WatchLaterButtonProps) {
  const { t } = useTranslation();
  const [inWatchLater, setInWatchLater] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const checkContentInUserWatchLater = async (
      authContext: AuthContextType,
    ) => {
      const response = await fetch(watchlaterServiceUrl, {
        mode: 'cors',
        headers: { Authorization: `Bearer ${authContext.token}` },
      });
      const watchLater: any[] = await response.json();
      const watchlaterList: string[] = watchLater.map((obj) => obj.contentId);

      if (props.contentId && watchlaterList.includes(props.contentId)) {
        setInWatchLater(true);
      }

      setIsLoading(false);
    };
    if (props.contentId) {
      checkContentInUserWatchLater(authContext);
    }
  }, [props, authContext, setInWatchLater, setIsLoading]);

  const handleWatchLater = async (event: any) => {
    if (inWatchLater) {
      try {
        await fetch(watchlaterServiceUrl + '/' + props.contentId, {
          mode: 'cors',
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext.token}`,
          },
        });
        setInWatchLater(false);
      } catch {
        console.log('Failed to remove content from watchlater.');
      }
    } else {
      try {
        const requestBody = JSON.stringify({
          contentId: props.contentId,
          contentType: props.contentType,
        });
        await fetch(watchlaterServiceUrl, {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext.token}`,
          },
          body: requestBody,
        });
        setInWatchLater(true);
      } catch {
        console.log('Failed to set content to watchlater.');
      }
    }
  };

  const renderIcon = () => {
    if (inWatchLater) {
      return <Bookmark sx={{ color: '#faaf00' }} />;
    }

    if (!props.contentId) {
      return <HourglassBottom />;
    }

    if (isLoading) {
      return <HourglassBottom />;
    }

    if (!isLoading && props.contentId && !inWatchLater) {
      return <Bookmark />;
    }
  };

  return (
    <Button
      startIcon={renderIcon()}
      sx={{ ml: 0, mb: 1, backgroundColor: '#340534' }}
      variant="contained"
      onClick={handleWatchLater}
    >
      {t('common.watchLater')}
    </Button>
  );
}

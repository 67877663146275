import React from 'react';

function BackgroundSvg() {
  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid slice"
      height="100%"
      width="100%"
    >
      <defs>
        <radialGradient
          id="Gradient1"
          cx="50%"
          cy="50%"
          fx="0.441602%"
          fy="50%"
          r=".5"
        >
          <animate
            attributeName="fx"
            dur="34s"
            values="0%;20%;0%"
            repeatCount="indefinite"
          ></animate>
          <stop offset="0%" stopColor="rgba(194, 141, 214, 1)"></stop>
          <stop offset="100%" stopColor="rgba(194, 141, 214, 0)"></stop>
        </radialGradient>
        <radialGradient
          id="Gradient2"
          cx="50%"
          cy="50%"
          fx="2.68147%"
          fy="50%"
          r=".5"
        >
          <animate
            attributeName="fx"
            dur="23.5s"
            values="0%;20%;0%"
            repeatCount="indefinite"
          ></animate>
          <stop offset="0%" stopColor="rgba(253, 207, 255, 1)"></stop>
          <stop offset="100%" stopColor="rgba(253, 207, 255, 0)"></stop>
        </radialGradient>
        <radialGradient
          id="Gradient3"
          cx="50%"
          cy="50%"
          fx="0.836536%"
          fy="50%"
          r=".5"
        >
          <animate
            attributeName="fx"
            dur="21.5s"
            values="0%;20%;0%"
            repeatCount="indefinite"
          ></animate>
          <stop offset="0%" stopColor="rgba(176, 97, 186, 1)"></stop>
          <stop offset="100%" stopColor="rgba(176, 97, 186, 0)"></stop>
        </radialGradient>
      </defs>
      <rect
        x="13.744%"
        y="1.18473%"
        width="100%"
        height="100%"
        fill="url(#Gradient1)"
        transform="rotate(334.41 50 50)"
      >
        <animate
          attributeName="x"
          dur="44s"
          values="25%;0%;25%"
          repeatCount="indefinite"
        ></animate>
        <animate
          attributeName="y"
          dur="42s"
          values="0%;25%;0%"
          repeatCount="indefinite"
        ></animate>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          dur="32s"
          repeatCount="indefinite"
        ></animateTransform>
      </rect>
      <rect
        x="-2.17916%"
        y="35.4267%"
        width="100%"
        height="100%"
        fill="url(#Gradient2)"
        transform="rotate(255.072 50 50)"
      >
        <animate
          attributeName="x"
          dur="43s"
          values="-25%;0%;-25%"
          repeatCount="indefinite"
        ></animate>
        <animate
          attributeName="y"
          dur="41s"
          values="0%;50%;0%"
          repeatCount="indefinite"
        ></animate>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          dur="27s"
          repeatCount="indefinite"
        ></animateTransform>
      </rect>
      <rect
        x="9.00483%"
        y="14.5733%"
        width="100%"
        height="100%"
        fill="url(#Gradient3)"
        transform="rotate(139.903 50 50)"
      >
        <animate
          attributeName="x"
          dur="41s"
          values="0%;25%;0%"
          repeatCount="indefinite"
        ></animate>
        <animate
          attributeName="y"
          dur="37s"
          values="0%;25%;0%"
          repeatCount="indefinite"
        ></animate>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="360 50 50"
          to="0 50 50"
          dur="26s"
          repeatCount="indefinite"
        ></animateTransform>
      </rect>
    </svg>
  );
}

export default BackgroundSvg;

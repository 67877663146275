import * as React from 'react';
import TopMenu from './components/topMenu/TopMenu';
import { Box } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import { AuthContext } from '../../contexts';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import Wizard from './components/wizard/Wizard';
import { BarLoader } from 'react-spinners';
import ContactUsSideButton from './components/contactUsSideButton/ContactUsSideButton';

interface AuthenticatedLayoutProps {}

function AuthenticatedLayout(props: AuthenticatedLayoutProps) {
  const { route } = useAuthenticator((context) => [context.user]);
  const [token, setToken] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingProfile, setIsLoadingProfile] = React.useState<boolean>(true);
  const [emailVerified, setEmailVerified] = React.useState<boolean>(false);
  const [isProfileCreated, setIsProfileCreated] =
    React.useState<boolean>(false);

  const navigate = useNavigate();

  if (route !== 'authenticated') {
    navigate('/sign-in');
  }

  React.useEffect(() => {
    const checkProfileAttribute = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userAttributes = await Auth.userAttributes(currentUser);
        const profile = userAttributes.find(
          (attr) => attr.Name === 'profile' && attr.Value,
        );
        if (profile) {
          setIsProfileCreated(true);
        }
        setIsLoadingProfile(false);
      } catch (error) {
        console.log(error);
      }
    };

    const getUserSessionData = async () => {
      try {
        const currentSession = await Auth.currentSession();
        const token = currentSession.getIdToken().getJwtToken();
        const email = currentSession.getIdToken().payload.email;
        const emailVerified =
          currentSession.getIdToken().payload.email_verified;
        setToken(token);
        setEmail(email);
        setEmailVerified(emailVerified);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    checkProfileAttribute();
    getUserSessionData();
  }, []);

  if (isLoading) {
    return (
      <AuthContext.Provider value={{ token, email, emailVerified }}>
        <TopMenu isLoading={isLoading} />
        <BarLoader
          color="rgb(203 101 167)"
          loading={isLoading}
          width={'100%'}
          height={2}
          aria-label="loader"
        />
      </AuthContext.Provider>
    );
  }

  if (!isProfileCreated && !isLoadingProfile) {
    return (
      <AuthContext.Provider value={{ token, email, emailVerified }}>
        <TopMenu isLoading={isLoading} />
        <Wizard />
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={{ token, email, emailVerified }}>
      <TopMenu isLoading={isLoading} />
      <Outlet />
      <ContactUsSideButton />
      <Box sx={{ height: 100 }} />
    </AuthContext.Provider>
  );
}

export default AuthenticatedLayout;
